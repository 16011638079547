import { useState } from "react"

export default function useBookingSelector(bookings, setBookings) {
  const [initiallyClicked, setInitiallyClicked] = useState([])
  const [selectedBooking, setSelectedBooking] = useState(null)
  const [hoveredBooking, setHoveredBooking] = useState(null)

  function markRange(from, to, destroy) {
    const updatedBookings = [...bookings]
    const lowest = Math.min(from.offsetInDays, to.offsetInDays)
    const highest = Math.max(from.offsetInDays, to.offsetInDays)

    updatedBookings.slice(lowest, highest + 1).forEach((booking) => {
      updatedBookings[booking.offsetInDays] = { ...booking, destroy }
    })

    setBookings(updatedBookings)
  }

  function handleBookingClick(booking) {
    handleClick([booking])
  }

  function handleWeekClick(week) {
    handleClick(weekToBookings(bookings, week))
  }

  function handleClick(clickedBookings) {
    if (initiallyClicked.length == 0) {
      const [firstBooking] = clickedBookings
      setSelectedBooking(firstBooking)
      setInitiallyClicked(clickedBookings)
    } else {
      markRange(selectedBooking, hoveredBooking, !selectedBooking.destroy)
      setSelectedBooking(null)
      setInitiallyClicked([])
    }
  }

  function bookingPending(booking) {
    if (selectedBooking == null || hoveredBooking == null) return false
    const lowest = Math.min(
      selectedBooking.offsetInDays,
      hoveredBooking.offsetInDays
    )
    const highest = Math.max(
      selectedBooking.offsetInDays,
      hoveredBooking.offsetInDays
    )
    return lowest <= booking.offsetInDays && highest >= booking.offsetInDays
  }

  function bookingSelected(booking) {
    return booking == selectedBooking
  }

  function bookingTooltipText(booking) {
    if (selectedBooking && booking == hoveredBooking) {
      const mode = selectedBooking.destroy ? "Vælger" : "Fravælger"
      const daysCount =
        Math.abs(selectedBooking.offsetInDays - hoveredBooking.offsetInDays) + 1
      const days = `${daysCount} ${daysCount == 1 ? "dag" : "dage"}`
      return `${mode} ${days} i alt`
    }
  }

  function handleWeekMouseOver(week) {
    const [firstBooking, lastBooking] = weekToBookings(bookings, week)
    if (
      selectedBooking &&
      selectedBooking.offsetInDays > lastBooking.offsetInDays
    ) {
      setSelectedBooking(initiallyClicked[initiallyClicked.length - 1])
      setHoveredBooking(firstBooking)
    } else {
      setSelectedBooking(initiallyClicked[0])
      setHoveredBooking(lastBooking)
    }
  }

  return {
    bookingPending,
    bookingSelected,
    bookingTooltipText,
    handleBookingClick,
    handleWeekClick,
    handleBookingMouseOver: setHoveredBooking,
    handleWeekMouseOver,
  }
}

function weekToBookings(bookings, week) {
  const weekBookings = week.days.map((day) => bookings[day.day - 1])

  const firstBooking = weekBookings[0]
  const lastBooking = weekBookings[weekBookings.length - 1]

  return [firstBooking, lastBooking]
}
