import React from "react"
import { ConstructionManager, Project } from "../ConstructionManagersCalendar"
import { DateTime, Interval } from "luxon"
import { createAbsences } from "../CalendarProjectResource"
import ResourceCalendarAbsence from "../ResourceCalendarAbsence"
import AbsenceTimeLine from "../AbsenceTimeLine"

const ConstructionManagerProject = ({
  project,
  calendarInterval,
  setSelectedProject,
  constructionManager,
  showDays,
  dayWidth,
}: {
  project: Project
  calendarInterval: Interval
  constructionManager: ConstructionManager
  dayWidth: number
  showDays: boolean
  setSelectedProject: ({
    state,
    project,
  }: {
    state: "closed" | "open"
    project: Project | null
  }) => void
}) => {
  const daysUntilStart = (project: Project) => {
    const expectedStart = project.startDate
      ? DateTime.fromISO(project.startDate)
      : nextMonday(calendarInterval.start || DateTime.now())

    return expectedStart
      .diff(calendarInterval.start || DateTime.now())
      .as("days")
  }

  const nextMonday = (startDate: DateTime) => {
    return startDate.plus({ week: 1 }).startOf("week")
  }

  return (
    <React.Fragment key={project.id}>
      <div
        className={`col-2 border-right text-right p-2 construction-manager--project d-flex align-items-center justify-content-between`}
        style={{ fontSize: 12 }}
      >
        <button
          type="button"
          className="btn btn-outline-dark btn-sm border-dark text-primary"
          onClick={() =>
            setSelectedProject({ state: "open", project: project })
          }
        >
          Detaljer
        </button>
        {project.projectManager} - {project.name}
      </div>
      <div className="col-10 py-2 px-0">
        <div
          className={`timeline `}
          style={{
            position: "relative",
            width: (project.durationInDays || 0) * dayWidth + "px",
            marginLeft: daysUntilStart(project) * dayWidth + "px",
          }}
        />
        <AbsenceTimeLine
          absences={constructionManager.futureAbsences}
          calendarInterval={calendarInterval}
          dayWidth={dayWidth}
          showDays={showDays}
        />
      </div>
    </React.Fragment>
  )
}

export default ConstructionManagerProject
