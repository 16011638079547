import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { DateTime, Interval } from "luxon"
import ProjectResourceGroup from "../ProjectResourceGroup"
import ProjectResourceAdder from "../ProjectResourceAdder"
import ProjectPagination from "../ProjectPagination"
import DateRange from "../DateRange"
import Grid from "../Grid"
import CalendarZoomer from "../CalendarZoomer"
import intervalFromOffsets from "../../utils/intervalFromOffsets"
import intervalFromDates from "../../utils/intervalFromDates"
import { ProjectBookerContext } from "../../contexts/ProjectBookerContext"
import jQuery from "jquery"
import useShowDays from "../../hooks/useShowDays"
import "./index.scss"
import camelizeProps from "../../utils/camelizeProps"

function ProjectBooker(props) {
  const {
    projectFromDb,
    resourceGroupsFromDb,
    durationInputSelector,
    startDateInputSelector,
    subContractorsFromDb,
  } = props

  const project = camelizeProps(projectFromDb)
  const resourceGroups = camelizeProps(resourceGroupsFromDb)
  const subContractors = camelizeProps(subContractorsFromDb)

  function parseStartDate(dateString) {
    const parsed = DateTime.fromISO(dateString)
    return parsed.isValid ? parsed : null
  }

  const startDateInput = document.querySelector(startDateInputSelector)

  const [startDate, setStartDate] = useState(() =>
    parseStartDate(projectFromDb.startDate)
  )

  function parseDuration(durationString) {
    return parseInt(durationString) || 0
  }
  const durationInput = document.querySelector(durationInputSelector)
  const [durationInDays, setDurationInDays] = useState(
    parseDuration(durationInput.value)
  )

  const [firstOffsetShown, setFirstOffsetShown] = useState(0)
  const [dayWidth, showDays, setShowDays] = useShowDays(false)

  const [projectResourceGroups, setProjectResourceGroups] = useState(
    project.projectResourceGroups
  )

  useEffect(() => {
    const form = startDateInput.form
    form.addEventListener("submit", (event) =>
      event.target.classList.add("submitting")
    )
    jQuery(window).on("beforeunload", () => {
      if (!form.classList.contains("submitting")) {
        jQuery(form).trigger("checkform.areYouSure")
      }
    })
    jQuery(form).areYouSure({ addRemoveFieldsMarksDirty: true })
  }, [])

  useEffect(() => {
    let durationListener = (event) => {
      setDurationInDays(parseDuration(event.target.value))
    }
    let startDateListener = (event) => {
      setStartDate(parseStartDate(event.detail.startDate))
    }
    durationInput.addEventListener("change", durationListener)
    startDateInput.addEventListener("startDateChanged", startDateListener)
    return () => {
      durationInput.removeEventListener("change", durationListener)
      startDateInput.removeEventListener("startDateChanged", startDateListener)
    }
  }, [])

  const weeksToShow = 6 * (showDays ? 1 : 5)
  const daysToShow = Math.min(durationInDays, weeksToShow * 7)

  const lastOffsetShown = Math.min(
    durationInDays - 1,
    firstOffsetShown + daysToShow - 1
  )

  const interval = useMemo(() => {
    let start = DateTime.fromISO(startDate).plus({ days: firstOffsetShown })
    let end = start
      .plus({ days: lastOffsetShown - firstOffsetShown })
      .endOf("day")
    return Interval.fromDateTimes(start, end)
  }, [startDate, daysToShow, firstOffsetShown, lastOffsetShown])

  const dateRangeInterval = startDate
    ? intervalFromDates(interval)
    : intervalFromOffsets(firstOffsetShown, lastOffsetShown)

  function handleResourceGroupSelect(resourceGroupIndex) {
    const resourceGroup = resourceGroups[resourceGroupIndex]
    const projectResourceGroup = {
      resourceGroup,
      bookings: [],
      projectResources: [],
    }
    setProjectResourceGroups([...projectResourceGroups, projectResourceGroup])
  }

  const handleJumpToToday = () => {
    const endDate = DateTime.fromISO(startDate).plus({ days: durationInDays })

    if (endDate.toMillis() < DateTime.now().toMillis()) {
      return
    }
    const today = DateTime.now().startOf("week")
    const offsetToday = Math.floor(
      (today - DateTime.fromISO(startDate)) / (1000 * 60 * 60 * 24)
    )

    setFirstOffsetShown(offsetToday)
  }

  useEffect(() => {
    if (
      startDate &&
      DateTime.fromISO(startDate).toMillis() < DateTime.now().toMillis() &&
      DateTime.fromISO(projectFromDb.cachedEndDate).toMillis() >
        DateTime.now().toMillis()
    ) {
      handleJumpToToday()
    }
  }, [startDate])

  function handleDestroyResourceGroup(resourceGroupIndex, destroy) {
    const updatedResourceGroups = [...projectResourceGroups]
    updatedResourceGroups[resourceGroupIndex] = {
      ...updatedResourceGroups[resourceGroupIndex],
      destroy: destroy,
    }
    setProjectResourceGroups(updatedResourceGroups)
  }

  return (
    <ProjectBookerContext.Provider
      value={{
        startDate,
        durationInDays,
        firstOffsetShown,
        lastOffsetShown,
        daysToShow,
        dayWidth,
        showDays,
      }}
    >
      <div className="project--booker">
        {durationInDays > 0 && (
          <>
            <div className="row">
              <div className="col-2"></div>
              <div className="col-10 pl-0 position-unset">
                <Grid
                  interval={dateRangeInterval}
                  dayWidth={dayWidth}
                  showDays={showDays}
                />
              </div>
            </div>
            <div className="sticky-top">
              <div className="row bg-white">
                <div className="col-2">
                  <CalendarZoomer setShowDays={setShowDays} />
                </div>
                <div className="col-10 pl-0 border-bottom">
                  <ProjectPagination
                    setFirstOffsetShown={setFirstOffsetShown}
                    handleJumpToToday={handleJumpToToday}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-2"></div>
                <div className="col-10 pl-0">
                  <DateRange interval={dateRangeInterval} dayWidth={dayWidth} />
                </div>
              </div>
            </div>
          </>
        )}
        {projectResourceGroups.map((projectResourceGroup, index) => (
          <ProjectResourceGroup
            index={index}
            key={index}
            projectResourceGroup={projectResourceGroup}
            handleDestroyResourceGroup={handleDestroyResourceGroup}
            subContractors={subContractors}
          />
        ))}
        <div className="row">
          <div className="col-2">
            <div className="form-group mt-3">
              <ProjectResourceAdder
                idSuffix="Group"
                resourceName="faggruppe"
                resources={resourceGroups}
                handleResourceSelect={handleResourceGroupSelect}
              />
            </div>
          </div>
          <div className="col-10"></div>
        </div>
      </div>
    </ProjectBookerContext.Provider>
  )
}

ProjectBooker.propTypes = {
  durationInputSelector: PropTypes.string.isRequired,
  startDateInputSelector: PropTypes.string.isRequired,
  projectFromDb: PropTypes.shape({
    startDate: PropTypes.string,
    cachedEndDate: PropTypes.string,
    project_resource_groups: PropTypes.array, // eslint-disable-line camelcase
  }).isRequired,
  resourceGroupsFromDb: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ).isRequired,
  subContractorsFromDb: PropTypes.array,
}

export default ProjectBooker
