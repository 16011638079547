import React, { useEffect, useState } from "react"
import { ProjectLink as ProjectLinkType } from "../ProjectLinks"
import "./index.scss"

const ProjectLink = ({
  projectLink,
  index,
  handleRemoveProjectLink,
}: {
  projectLink: ProjectLinkType
  index: number
  handleRemoveProjectLink: (index: number) => void
}) => {
  const [url, setUrl] = useState(projectLink.url)
  const [title, setTitle] = useState(projectLink.title || "")

  useEffect(() => {
    if (projectLink.url && projectLink.title) {
      setUrl(projectLink.url)
      setTitle(projectLink.title)
    }
  }, [projectLink])

  return (
    <>
      {!projectLink.destroy && (
        <div className="project-link-grid">
          <div className="">
            <input
              disabled={title === "Business Central" || title === "SharePoint"}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Titel"
              value={title}
            />
          </div>
          <div className="">
            <input
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Url"
              type="text"
              className="form-control"
              value={url}
            />
          </div>
          <div className="d-flex gap">
            <a href={url} target="_blank">
              <button type="button" className="btn btn-primary">
                Åbn
              </button>
            </a>
            {title !== "Business Central" && title !== "SharePoint" && (
              <button
                type="button"
                className=" btn btn-danger"
                onClick={() => handleRemoveProjectLink(index)}
              >
                Fjern
              </button>
            )}
          </div>
        </div>
      )}

      {url && title && (
        <>
          <input
            type="hidden"
            name={`project[project_links_attributes][${index}][id]`}
            value={projectLink.id}
          />
          <input
            type="hidden"
            name={`project[project_links_attributes][${index}][title]`}
            value={title}
          />
          <input
            type="hidden"
            name={`project[project_links_attributes][${index}][url]`}
            value={url}
          />
          <input
            type="hidden"
            name={`project[project_links_attributes][${index}][_destroy]`}
            value={(projectLink.destroy || false).toString()}
          />
        </>
      )}
    </>
  )
}

export default ProjectLink
