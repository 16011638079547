import React from "react"
import PropTypes from "prop-types"
import { DateTime } from "luxon"
import "./index.scss"
import forwardIcon from "../../images/forward.svg"
import backwardIcon from "../../images/backward.svg"

export default function DateHopper(props) {
  const { startDate, setStartDate, className } = props

  function forward() {
    setStartDate(startDate.plus({ month: 1 }))
  }

  function back() {
    setStartDate(startDate.minus({ month: 1 }))
  }

  function today() {
    setStartDate(DateTime.local().startOf("day"))
  }

  return (
    <div className={`${className} btn-group`}>
      <button
        type="button"
        className="btn font-weight-bold text-primary"
        aria-label="back"
        onClick={back}
      >
        <img src={forwardIcon} style={{ width: "30px", height: "30px" }} />
      </button>
      <button
        type="button"
        className="btn font-weight-bold datehopper--today  text-primary"
        aria-label="today"
        onClick={today}
      >
        I dag
      </button>
      <button
        type="button"
        className="btn font-weight-bold text-primary"
        aria-label="forward"
        onClick={forward}
      >
        <img src={backwardIcon} style={{ width: "30px", height: "30px" }} />
      </button>
    </div>
  )
}

DateHopper.propTypes = {
  className: PropTypes.string,
  startDate: PropTypes.object,
  setStartDate: PropTypes.func,
}
