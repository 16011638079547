import React, { useState, useEffect, useRef } from "react"
import DatePicker from "react-datepicker"
import { registerLocale } from "react-datepicker"
import PropTypes from "prop-types"
import { DateTime } from "luxon"
import da from "date-fns/locale/da"

registerLocale("da", da)

import "react-datepicker/dist/react-datepicker.css"
import "./index.scss"

function ProjectStartDate(props) {
  const { startDate, setStartDate } = props
  const [datePickerValue, setDatePickerValue] = useState()
  const [inputValue, setInputValue] = useState("")
  const hiddenInput = useRef()

  useEffect(() => {
    if (startDate) {
      setDatePickerValue(DateTime.fromISO(startDate).toJSDate())
      setInputValue(startDate)
    } else {
      setDatePickerValue("")
      setInputValue("")
    }
  }, [startDate])

  const handleChange = (date) => {
    setStartDate(DateTime.fromJSDate(date).toISO())
    hiddenInput.current.dispatchEvent(
      new CustomEvent("startDateChanged", {
        bubbles: false,
        detail: { startDate: DateTime.fromJSDate(date).toISO() },
      })
    )
  }

  return (
    <>
      <label htmlFor="project_start_date">Start dato</label>
      <br />
      <input
        type="hidden"
        name="project[start_date]"
        value={inputValue}
        id="project_start_date"
        ref={hiddenInput}
      />
      <DatePicker
        dateFormat="dd/MM/yyyy"
        id="project_start_date"
        selected={datePickerValue}
        onChange={handleChange}
        className="form-control w-100"
        locale="da"
        showWeekNumbers
      />
    </>
  )
}

ProjectStartDate.propTypes = {
  startDate: PropTypes.string,
  setStartDate: PropTypes.func,
}

export default ProjectStartDate
