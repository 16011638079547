import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import warning from "../../images/warning.svg"
import { Overlay, Tooltip } from "react-bootstrap"

function Warning(props) {
  const { message, onClick } = props

  const ref = useRef()
  const [showMessage, setShowMessage] = useState(false)

  return (
    <span className="warning-image d-inline-block">
      <img
        ref={ref}
        alt={message}
        onClick={onClick}
        src={warning}
        onMouseOver={() => {
          setShowMessage(true)
        }}
        onMouseOut={() => {
          setShowMessage(false)
        }}
      />
      <Overlay
        target={ref}
        show={showMessage}
        transition={false}
        placement="bottom"
      >
        {(props) => <Tooltip {...props}>{message}</Tooltip>}
      </Overlay>
    </span>
  )
}

Warning.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func,
}

export default Warning
