import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { DateTime, Interval } from "luxon"
import "./index.scss"
import CalendarProjectResourceGroup from "../CalendarProjectResourceGroup"
import Warning from "../Warning"
import MissingResources from "../MissingResources"

function CalendarProject(props) {
  const {
    project,
    setProject,
    dayWidth,
    showDays,
    calendarInterval,
    doubleBookings,
    editParams,
    setSelectedProject,
    canEditProjects,
  } = props
  const doubleBooked = doubleBookings?.length > 0
  const doubleBookedMsg = "Der er dobbelt bookings"
  const resourcesMissing = "Ingen ressourcer er booket på projektet"
  const formattedEditParams = editParams ? `?${editParams}` : ""

  const calendarStartDate = calendarInterval.start
  const expectedStart = project.startDate || nextMonday(calendarStartDate)
  const daysUntilStart = DateTime.fromISO(expectedStart)
    .diff(calendarStartDate)
    .as("days")

  const projectResourceGroups = project.projectResourceGroups

  const note = project.note?.split("\n\n").map((paragraph, i) => (
    <p key={i}>
      {paragraph.split("\n").map((line, j) => (
        <Fragment key={j}>
          {line}
          <br />
        </Fragment>
      ))}
    </p>
  ))

  function handleExpandAll(event) {
    event.stopPropagation()
    let projectResourceGroups = project.projectResourceGroups.map(
      (projectResourceGroup) => {
        return { ...projectResourceGroup, expanded: true }
      }
    )
    setProject({ ...project, projectResourceGroups, expanded: true })
  }

  function setProjectResourceGroup(updatedProjectResourceGroup, index) {
    const updatedProjectResourceGroups = [...projectResourceGroups]
    updatedProjectResourceGroups[index] = updatedProjectResourceGroup
    setProject({
      ...project,
      projectResourceGroups: updatedProjectResourceGroups,
    })
  }

  const allocatedResourcesCount = projectResourceGroups.reduce(
    (previousValue, newValue) => {
      return previousValue + newValue.projectResources.length
    },
    0
  )

  return (
    <>
      <div className={"row border-bottom"}>
        <div
          className="project--name col-2 border-right p-2 text-left"
          onClick={() =>
            setProject({ ...project, expanded: !project.expanded })
          }
        >
          <div className="row">
            <div className="col">
              <div className="d-flex expand-resource-group align-items-center text-truncate justify-content-between">
                <span>
                  <span className="font-weight-bold" data-testid={project.id}>
                    {project.id}{" "}
                  </span>

                  <span
                    className="font-weight-bold"
                    aria-label={"Expand " + project.name}
                    data-testid={project.name}
                  >
                    {project.name}
                  </span>

                  <span className="project--id">
                    {project.constructionManager?.initials ? (
                      <> {project.constructionManager?.initials}</>
                    ) : (
                      ""
                    )}
                  </span>
                </span>

                <span>
                  <span>{project.expanded ? "-" : "+"}</span>
                </span>
              </div>
            </div>
          </div>
          <div className="fs-small ml-0 project-warnings-and-edit">
            {canEditProjects && (
              <a
                className="font-weight-light"
                href={`/projects/${project.id}/edit${formattedEditParams}`}
                aria-label={`Redigér ${project.name}`}
              >
                Redigér
              </a>
            )}
            {allocatedResourcesCount === 0 && (
              <MissingResources
                message={resourcesMissing}
                onClick={handleExpandAll}
              />
            )}
            {doubleBooked && (
              <Warning message={doubleBookedMsg} onClick={handleExpandAll} />
            )}
          </div>
        </div>
        <div className="col-10 project p-0">
          <div
            className={`${!project.startDate ? "estimate" : ""} timeline`}
            style={{
              width: project.durationInDays * dayWidth + "px",
              marginLeft: daysUntilStart * dayWidth + "px",
            }}
          ></div>
        </div>
        {project.expanded && (
          <>
            {projectResourceGroups.map((projectResourceGroup, index) => (
              <CalendarProjectResourceGroup
                dayWidth={dayWidth}
                showDays={showDays}
                doubleBookings={doubleBookings}
                calendarInterval={calendarInterval}
                key={projectResourceGroup.id}
                offsetMargin={daysUntilStart * dayWidth}
                setProjectResourceGroup={(updatedProjectResourceGroup) =>
                  setProjectResourceGroup(updatedProjectResourceGroup, index)
                }
                projectResourceGroup={projectResourceGroup}
              />
            ))}
            <div className="col-2 border-right text-left project--actions p-2">
              <button
                type="button"
                className="btn btn-outline-dark btn-sm border-dark text-primary"
                onClick={() =>
                  setSelectedProject({ state: "open", project: project })
                }
              >
                Detaljer
              </button>
            </div>
            <div className="col-10"></div>
          </>
        )}
        <div
          className="modal fade"
          id={`noteModal${project.id}`}
          tabIndex="-1"
          role="dialog"
          aria-label={`Note for projekt ${project.id}`}
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">{note}</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Luk
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

CalendarProject.propTypes = {
  setProject: PropTypes.func,
  setSelectedProject: PropTypes.func,
  canEditProjects: PropTypes.bool,
  doubleBookings: PropTypes.array,
  editParams: PropTypes.string,
  project: PropTypes.shape({
    constructionManager: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      initials: PropTypes.string.isRequired,
    }),
    expanded: PropTypes.bool,
    note: PropTypes.string,
    durationInDays: PropTypes.number,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    projectResourceGroups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ),
    startDate: PropTypes.string,
  }),
  dayWidth: PropTypes.number.isRequired,
  showDays: PropTypes.bool,
  calendarInterval: PropTypes.instanceOf(Interval).isRequired,
}

function nextMonday(startDate) {
  return startDate.plus({ week: 1 }).startOf("week")
}

export default CalendarProject
