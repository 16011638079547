import React, { useContext } from "react"
import PropTypes from "prop-types"
import { ProjectBookerContext } from "../../contexts/ProjectBookerContext"
import { DateTime } from "luxon"

// import "./index.scss"

export default function ProjectPagination(props) {
  const { durationInDays, firstOffsetShown, daysToShow, startDate } =
    useContext(ProjectBookerContext)

  const { setFirstOffsetShown, handleJumpToToday } = props

  function lastPossibleOffset() {
    if (durationInDays == 0) return 0
    return (Math.ceil(durationInDays / daysToShow) - 1) * daysToShow
  }

  const handleDecreaseOffsetShown = () => {
    const newOffset = Math.max(0, firstOffsetShown - daysToShow)
    setFirstOffsetShown(newOffset)
  }
  const handleDecreaseOffsetShownStart = () => {
    setFirstOffsetShown(0)
  }
  const handleIncreaseOffsetShown = () => {
    const newOffset = Math.min(
      firstOffsetShown + daysToShow,
      lastPossibleOffset()
    )
    setFirstOffsetShown(newOffset)
  }
  const handleIncreaseOffsetShownEnd = () => {
    const newOffset = lastPossibleOffset()
    setFirstOffsetShown(newOffset)
  }

  const showStartDate =
    startDate &&
    DateTime.fromISO(startDate).toMillis() < DateTime.now().toMillis()

  return (
    <div className="d-flex justify-content-between w-50">
      <span>
        {firstOffsetShown > 0 && (
          <>
            <a
              className="btn btn-sm"
              style={{ cursor: "pointer" }}
              onClick={handleDecreaseOffsetShownStart}
            >
              &laquo;
            </a>{" "}
            <a
              className="btn btn-sm"
              style={{ cursor: "pointer" }}
              onClick={handleDecreaseOffsetShown}
            >
              &lsaquo;
            </a>
          </>
        )}
      </span>
      <span>
        {showStartDate && (
          <a
            className="btn btn-sm"
            style={{ cursor: "pointer" }}
            onClick={handleJumpToToday}
          >
            I dag
          </a>
        )}
      </span>
      <span>
        {firstOffsetShown < lastPossibleOffset() && (
          <>
            <a
              className="btn btn-sm"
              style={{ cursor: "pointer" }}
              onClick={handleIncreaseOffsetShown}
            >
              &rsaquo;
            </a>{" "}
            <a
              className="btn btn-sm"
              style={{ cursor: "pointer" }}
              onClick={handleIncreaseOffsetShownEnd}
            >
              &raquo;
            </a>
          </>
        )}
      </span>
    </div>
  )
}

ProjectPagination.propTypes = {
  setFirstOffsetShown: PropTypes.func,
  handleJumpToToday: PropTypes.func,
}
