import React, { useState } from "react"
import { Roles, User } from "../Users"
import { useCreateUser } from "../../hooks/useUsers"
import "./index.scss"

const UserCreateForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const availableRoles: Roles[] = ["admin", "planner", "user"]

  const createUser = useCreateUser()
  const [name, setName] = useState<User["name"]>("")
  const [email, setEmail] = useState<User["email"]>("")
  const [password, setPassword] = useState<string>("")
  const [passwordError, setPasswordError] = useState<boolean>(true)
  const [role, setRole] = useState<User["role"]>("user")

  const handleRoleClick = (role: Roles) => {
    setRole(role)
  }

  const handlePassword = (e: string) => {
    if (e.length < 8) {
      setPasswordError(true)
    } else {
      setPasswordError(false)
    }

    setPassword(e)
  }

  const roles = () => {
    return availableRoles.map((availablRole, index) => (
      <div
        key={index}
        onClick={() => handleRoleClick(availablRole)}
        className={`role border click-effect ${
          role === availablRole ? "border-success" : ""
        }`}
      >
        {availablRole}
      </div>
    ))
  }

  const handleSave = () => {
    createUser.mutate(
      { email: email, password: password, name: name || "", role: role },
      {
        onSuccess: () => {
          onSuccess()
        },
      }
    )
  }

  return (
    <div className="p-5">
      {/* <h3>{user.email}</h3> */}
      <div className="form-group mt-3">
        <label htmlFor="email">Email</label>
        <input
          className="form-control rounded-0"
          type="email"
          value={email || ""}
          onChange={(e) => setEmail(e.target.value)}
          id="email"
        />
      </div>
      <div className="form-group mt-3">
        <label htmlFor="name">Navn</label>
        <input
          className="form-control rounded-0"
          type="text"
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
          id="name"
        />
      </div>
      <div className="form-group mt-3">
        <label htmlFor="password">Password</label>
        <input
          className={`form-control rounded-0 password ${
            passwordError ? "border-danger" : ""
          }`}
          type="text"
          value={password || ""}
          onChange={(e) => handlePassword(e.target.value)}
          id="password"
        />
        {passwordError && (
          <span className="password-error text-danger">Minimum 8 tegn</span>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="role">Rolle</label>
        <div className="roles">{roles()}</div>
      </div>
      <div className="dropdown-divider mt-3"></div>
      <button
        className="btn btn-primary rounded-0 w-100 mt-3"
        onClick={handleSave}
        disabled={passwordError}
      >
        Gem
      </button>
    </div>
  )
}

export default UserCreateForm
