import React from "react"
import PropTypes from "prop-types"
import zoomIn from "../../images/zoom-in.svg"
import zoomOut from "../../images/zoom-out.svg"

export default function CalendarZoomer(props) {
  const { setShowDays, className } = props

  function handleZoomInClick() {
    setShowDays(true)
  }
  function handleZoomOutClick() {
    setShowDays(false)
  }

  return (
    <div className={`${className} btn-group `}>
      <button
        aria-label="Zoom ind"
        type="button"
        className="btn  font-weight-bold text-primary"
        onClick={handleZoomInClick}
      >
        <img src={zoomIn} style={{ width: "30px", height: "30px" }} />
      </button>
      <button
        type="button"
        className="btn  font-weight-bold text-primary"
        aria-label="Zoom ud"
        onClick={handleZoomOutClick}
      >
        <img src={zoomOut} style={{ width: "30px", height: "30px" }} />
      </button>
    </div>
  )
}

CalendarZoomer.propTypes = {
  className: PropTypes.string,
  setShowDays: PropTypes.func,
}
