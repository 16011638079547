import React, { useState } from "react"
import PropTypes from "prop-types"
import "./index.scss"
import ProjectResource from "../ProjectResource"
import ProjectBookings from "../ProjectBookings"
import ProjectResourceAdder from "../ProjectResourceAdder"
import useBookings from "../../hooks/useBookings"
import { Button } from "react-bootstrap"

function ProjectResourceGroup(props) {
  const {
    index,
    projectResourceGroup,
    subContractors,
    handleDestroyResourceGroup,
  } = props

  const resourceGroup = projectResourceGroup.resourceGroup
  const resources = resourceGroup.subContractor
    ? resourceGroup.resources.concat(subContractors)
    : resourceGroup.resources
  const inputFieldPrefix = `project[project_resource_groups_attributes][${index}]`

  const [projectResources, setProjectResources] = useState(
    projectResourceGroup.projectResources
  )

  const { bookings, setBookings } = useBookings(
    projectResourceGroup.bookings,
    resourceGroup.color,
    inputFieldPrefix
  )

  function handleResourceSelect(resourceIndex) {
    const resourceable = resources[resourceIndex]
    const projectResource = {
      resourceable,
      resourceableType: resourceable.type,
      bookings: bookings.map((booking) => ({
        offsetInDays: booking.offsetInDays,
        destroy: booking.destroy,
      })),
    }
    setProjectResources([...projectResources, projectResource])
  }

  function handleRemoveResource(resourceIndex) {
    const updatedResources = [...projectResources]
    updatedResources[resourceIndex] = {
      ...updatedResources[resourceIndex],
      destroy: true,
    }
    setProjectResources(updatedResources)
  }

  return (
    <div
      className={`row align-items-center project-resource-group border-bottom  ${
        projectResourceGroup.destroy ? "deleted" : ""
      }`}
    >
      <div className="col-2 project-resource-group--name">
        <div className="row">
          <div className="col text-truncate">{resourceGroup.name}</div>
          <div className="col-auto">
            <Button
              onClick={() => {
                handleDestroyResourceGroup(index, true)
              }}
              variant=""
              size="sm"
              aria-label={`Fjern ${resourceGroup.name}`}
              className="text-danger p-0"
            >
              Slet gruppe
            </Button>
          </div>
        </div>
        <span>
          ({bookings.filter((booking) => booking.destroy != true).length} dage)
        </span>
      </div>
      <div className="col-10 text-nowrap project-resource-group--bookings p-0">
        <input
          type="hidden"
          name={`${inputFieldPrefix}[id]`}
          value={projectResourceGroup.id}
        />
        <input
          type="hidden"
          name={`${inputFieldPrefix}[resource_group_id]`}
          value={resourceGroup.id}
        />
        <input
          type="hidden"
          name={`${inputFieldPrefix}[_destroy]`}
          value={projectResourceGroup.destroy || "false"}
        />
        <ProjectBookings
          bookings={bookings}
          setBookings={setBookings}
          color={resourceGroup.color}
          inputFieldPrefix={inputFieldPrefix}
        />
      </div>
      {projectResources.map((projectResource, resourceIndex) => (
        <ProjectResource
          handleRemoveResource={() => handleRemoveResource(resourceIndex)}
          key={resourceIndex}
          projectResource={projectResource}
          parentInputFieldPrefix={inputFieldPrefix}
          resourceIndex={resourceIndex}
          resourceGroup={resourceGroup}
        />
      ))}
      <div className="col-2 my-3">
        <ProjectResourceAdder
          idSuffix={index}
          resourceName="resource"
          resources={resources}
          handleResourceSelect={handleResourceSelect}
        />
      </div>
      <div className="col-10"></div>
      {projectResourceGroup.destroy && (
        <div className="overlay">
          <div className="inner">
            <div className="bg-danger text-white p-3 rounded">
              <p className="d-inline">Gruppen bliver slettet</p>
              <Button
                size="sm"
                variant=""
                className="text-white text-underline py-0 ml-4 undo-delete"
                onClick={() => handleDestroyResourceGroup(index, false)}
              >
                <u>Fortryd</u>
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

ProjectResourceGroup.propTypes = {
  index: PropTypes.number,
  projectResourceGroup: PropTypes.shape({
    bookings: PropTypes.arrayOf(
      PropTypes.shape({
        offsetInDays: PropTypes.number,
      })
    ),
    destroy: PropTypes.bool,
    id: PropTypes.number,
    projectResources: PropTypes.arrayOf(
      PropTypes.shape({
        resourceable: PropTypes.shape({
          name: PropTypes.string,
        }).isRequired,
      })
    ).isRequired,
    resourceGroup: PropTypes.shape({
      color: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
        })
      ).isRequired,
      subContractor: PropTypes.bool,
    }).isRequired,
  }),
  subContractors: PropTypes.array,
  handleDestroyResourceGroup: PropTypes.func,
}

export default ProjectResourceGroup
