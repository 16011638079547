import React from "react"
import PropTypes from "prop-types"

export default function ResourceCalendarAbsence(props) {
  const { width, offsetMargin, approved, marginTop, top } = props

  const defaultStyle = {
    position: "absolute",
    left: offsetMargin,
    top: top,
    width: width,
    marginTop: marginTop,
    textAlign: "center",
    height: "25px",
    color: approved ? "black" : "red",
  }

  return <span style={defaultStyle}>F</span>
}

ResourceCalendarAbsence.propTypes = {
  width: PropTypes.number,
  offsetMargin: PropTypes.number,
  marginTop: PropTypes.number,
  approved: PropTypes.bool,
  top: PropTypes.number,
}
