import React from "react"
import PropTypes from "prop-types"
import "./index.scss"

export default function Grid(props) {
  const { interval, dayWidth, showDays } = props

  const weeks = interval.weeks()

  return (
    <div className="grid text-nowrap">
      {weeks &&
        weeks.map((week, index) => (
          <div
            key={index}
            className="week"
            style={{ width: dayWidth * week.days.length + "px" }}
          >
            {showDays &&
              week.days.map((day, index) => (
                <div
                  key={index}
                  className={`grid-day day ${day.weekday > 5 ? "weekend" : ""}`}
                  style={{ width: dayWidth }}
                ></div>
              ))}
          </div>
        ))}
    </div>
  )
}

Grid.propTypes = {
  interval: PropTypes.object.isRequired,
  dayWidth: PropTypes.number,
  showDays: PropTypes.bool.isRequired,
}
