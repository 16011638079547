import React, { useMemo, useState, useLayoutEffect, useRef } from "react"
import PropTypes from "prop-types"
import { DateTime, Interval } from "luxon"
import "./index.scss"
import DateRange from "../DateRange"
import CalendarProject from "../CalendarProject"
import DateHopper from "../DateHopper"
import CalendarZoomer from "../CalendarZoomer"
import Grid from "../Grid"
import CalendarCategoryFilter from "../CalendarCategoryFilter"
import CalendarSearch from "../CalendarSearch"
import CalendarExpandAll from "../CalendarExpandAll"
import CalendarShowArchived from "../CalendarShowArchived"
import intervalFromDates from "../../utils/intervalFromDates"
import useShowDays from "../../hooks/useShowDays"
import camelizeProps from "../../utils/camelizeProps"
import Sidebar from "../Sidebar"
import ProjectDetails from "../ProjectDetails"

const Calendar = (props) => {
  const {
    projectsFromDB,
    clearSearchPath,
    projectCategoriesFromDb,
    doubleBookingsFromDb,
    projectEditParams,
    canEditProjects,
  } = { projectCategoriesFromDb: [], ...props }
  const projectCategories = camelizeProps(projectCategoriesFromDb)
  const doubleBookings = camelizeProps(doubleBookingsFromDb)

  const [dayWidth, showDays, setShowDays] = useShowDays(false)
  const [startDate, setStartDate] = useState(DateTime.local())
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [projects, setProjects] = useState(camelizeProps(projectsFromDB))
  const [showArchived, setShowArchived] = useState(false)
  const [selectedProject, setSelectedProject] = useState({
    state: "closed",
    project: null,
  })

  const formRef = useRef()

  function setExpandAll(state) {
    let updatedProjects = null
    if (state == 0) {
      updatedProjects = projects.map((project) => {
        return { ...project, expanded: false }
      })
    } else if (state == 1) {
      updatedProjects = projects.map((project) => {
        let projectResourceGroups = project.projectResourceGroups.map(
          (projectResourceGroup) => {
            return { ...projectResourceGroup, expanded: false }
          }
        )
        return { ...project, projectResourceGroups, expanded: true }
      })
    } else {
      // state == 2
      updatedProjects = projects.map((project) => {
        let projectResourceGroups = project.projectResourceGroups.map(
          (projectResourceGroup) => {
            return { ...projectResourceGroup, expanded: true }
          }
        )
        return { ...project, projectResourceGroups, expanded: true }
      })
    }
    setProjects(updatedProjects)
  }

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  const daysToShow = Math.floor(windowWidth / dayWidth)

  const interval = useMemo(() => {
    let start = DateTime.fromISO(startDate).startOf("week")
    let end = start.plus({ days: daysToShow }).endOf("week").endOf("day")
    return Interval.fromDateTimes(start, end)
  }, [startDate, daysToShow])

  return (
    <div id="calendar">
      <div className="row">
        <div className="col-2 border-right"></div>
        <div className="col-10 pl-0 position-unset">
          <Grid
            interval={intervalFromDates(interval)}
            dayWidth={dayWidth}
            showDays={showDays}
          />
        </div>
      </div>
      <form ref={formRef} className="sticky-top">
        <div className="row p-2 px-4 border-bottom bg-white align-items-center d-flex justify-content-between height-top-bars calendar-actions">
          <div className="d-flex justify-content-start align-items-center calendar-actions-start">
            <div className="d-flex calendar-actions-links">
              <button
                type="button"
                className="active d-flex align-items-center "
              >
                Projekter
              </button>
              <a className="d-flex align-items-center " href="/resources">
                Håndværkere
              </a>
              <a
                className="d-flex align-items-center "
                href="/construction-managers"
              >
                Administration
              </a>
            </div>
            <div className="d-flex">
              <CalendarExpandAll className="mr-3" setExpandAll={setExpandAll} />
              <CalendarCategoryFilter
                className="mr-3"
                categories={projectCategories}
                formRef={formRef}
              />
              <CalendarShowArchived
                className="mr-3"
                setShowArchived={setShowArchived}
                value={showArchived}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center ">
            <DateHopper
              className="mr-3"
              startDate={startDate}
              setStartDate={setStartDate}
            />
            <CalendarZoomer setShowDays={setShowDays} />
          </div>
        </div>
        <div className="row border-bottom">
          <div className="col-2 calendar--timeline border-right p-0">
            <CalendarSearch clearSearchPath={clearSearchPath} />
          </div>
          <div className="col-10 text-nowrap p-0">
            <DateRange
              interval={intervalFromDates(interval)}
              dayWidth={dayWidth}
              showDays={showDays}
            />
          </div>
        </div>
      </form>
      {projects.map((project, index) => {
        if (!showArchived && project.archived) {
          return false
        }
        return (
          <CalendarProject
            key={project.id}
            project={project}
            canEditProjects={canEditProjects}
            setSelectedProject={setSelectedProject}
            dayWidth={dayWidth}
            showDays={showDays}
            doubleBookings={doubleBookings[project.id]}
            calendarInterval={interval}
            editParams={projectEditParams}
            setProject={(updatedProject) => {
              const updatedProjects = [...projects]
              updatedProjects[index] = updatedProject
              setProjects(updatedProjects)
            }}
          />
        )
      })}
      <Sidebar
        visible={selectedProject.state === "open"}
        onDismiss={() => setSelectedProject({ state: "closed", project: null })}
      >
        {selectedProject.project && (
          <ProjectDetails
            project={selectedProject.project}
            canEditProjects={canEditProjects}
            editParams={projectEditParams}
            calendarType={"projects"}
          />
        )}
      </Sidebar>
    </div>
  )
}

Calendar.propTypes = {
  canEditProjects: PropTypes.bool,
  clearSearchPath: PropTypes.string,
  doubleBookingsFromDb: PropTypes.object.isRequired,
  projectCategoriesFromDb: PropTypes.array,
  projectsFromDB: PropTypes.array.isRequired,
  projectEditParams: PropTypes.string,
}

export default Calendar
