import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import warning from "../../images/absence.svg"
import { Overlay, Tooltip } from "react-bootstrap"
import "./index.scss"

export default function ResourceCalendarAbsenceWarning(props) {
  const { message } = props

  const ref = useRef()
  const [showMessage, setShowMessage] = useState(false)

  return (
    <span className="absence-warning-image d-inline-block ml-1">
      <img
        ref={ref}
        alt={message}
        src={warning}
        aria-label="Feriedag med booking"
        onMouseOver={() => {
          setShowMessage(true)
        }}
        onMouseOut={() => {
          setShowMessage(false)
        }}
      />
      <Overlay target={ref} show={showMessage} transition={false}>
        <Tooltip>{message}</Tooltip>
      </Overlay>
    </span>
  )
}

ResourceCalendarAbsenceWarning.propTypes = {
  message: PropTypes.string,
}
