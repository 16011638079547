import { DateTime, Interval } from "luxon"
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react"
import useShowDays from "../../hooks/useShowDays"
import camelizeProps from "../../utils/camelizeProps"
import intervalFromDates from "../../utils/intervalFromDates"
import CalendarSearch from "../CalendarSearch"
import CalendarShowArchived from "../CalendarShowArchived"
import CalendarZoomer from "../CalendarZoomer"
import DateHopper from "../DateHopper"
import DateRange from "../DateRange"
import Grid from "../Grid"
import ConstructionManagerProjects from "../ConstructionManagerProjects"
import ProjectDetails from "../ProjectDetails"
import Sidebar from "../Sidebar"
import ResourceCalendarExpandAll from "../ResourceCalendarExpandAll"
import { ProjectLink } from "../ProjectLinks"

export interface ConstructionManager {
  id: number
  createdAt: string
  updatedAt: string
  name: string
  color: null
  resourceGroupId: number
  deletedAt: null
  crewId: null
  initials: string
  productionManager: string
  futureAbsences: any[]
  projects?: Project[]
  expanded: boolean
}

export type Project = {
  id: number
  name: string
  durationInDays: number | null
  startDate: string | null
  createdAt: string
  updatedAt: string
  category: string
  note: string | null
  cachedEndDate: string | null
  completed: boolean
  archived: boolean
  constructionManagerId: number
  constructionManager: ConstructionManager
  projectManager: string
  projectLinks: ProjectLink[]
}

type ConstructionManagersCalendarProps = {
  constructionManagersFromDb: ConstructionManager[]
  canEditProjects: boolean
  clearSearchPath: string
  projectEditParams: string
}

const ConstructionManagersCalendar = (
  props: ConstructionManagersCalendarProps
) => {
  const { constructionManagersFromDb, clearSearchPath, canEditProjects } = props
  const [dayWidth, showDays, setShowDays] = useShowDays(false)
  const [startDate, setStartDate] = useState(DateTime.local())
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [expandAll, setExpandAll] = useState<0 | 1>(0)
  const [constructionManagers, setConstructionManagers] = useState<
    ConstructionManager[]
  >(camelizeProps(constructionManagersFromDb))
  const [showArchived, setShowArchived] = useState(false)
  const [selectedProject, setSelectedProject] = useState<{
    state: "closed" | "open"
    project: null | Project
  }>({
    state: "closed",
    project: null,
  })

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  const daysToShow = Math.floor(windowWidth / dayWidth)

  const interval = useMemo(() => {
    let start = startDate.startOf("week")
    let end = start.plus({ days: daysToShow }).endOf("week").endOf("day")
    return Interval.fromDateTimes(start, end)
  }, [startDate, daysToShow])

  useEffect(() => {
    let updatedConstructionManagers: ConstructionManager[] = []

    console.log(expandAll)

    if (expandAll == 0) {
      updatedConstructionManagers = constructionManagers.map(
        (constructionManager) => {
          return { ...constructionManager, expanded: false }
        }
      )
    } else if (expandAll == 1) {
      updatedConstructionManagers = constructionManagers.map(
        (constructionManager) => {
          return { ...constructionManager, expanded: true }
        }
      )
    }

    console.log(updatedConstructionManagers)

    setConstructionManagers(updatedConstructionManagers)
  }, [expandAll])

  return (
    <div id="calendar">
      <div className="row">
        <div className="col-2 border-right"></div>
        <div className="col-10 pl-0 position-unset">
          <Grid
            interval={intervalFromDates(interval)}
            dayWidth={dayWidth}
            showDays={showDays}
          />
        </div>
      </div>
      <form className="sticky-top">
        <div className="row p-2 px-4 border-bottom bg-white align-items-center d-flex justify-content-between height-top-bars calendar-actions">
          <div className="d-flex justify-content-start align-items-center calendar-actions-start">
            <div className="d-flex calendar-actions-links">
              <a className=" d-flex align-items-center" href="/projects">
                Projekter
              </a>
              <a className="d-flex align-items-center" href="/resources">
                Håndværkere
              </a>
              <a
                className="d-flex align-items-center active"
                href="/construction-managers"
              >
                Administration
              </a>
            </div>
            <div className="d-flex">
              <ResourceCalendarExpandAll
                className="mr-3"
                setExpandAll={setExpandAll}
                value={expandAll}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <DateHopper
              className="mr-3"
              startDate={startDate}
              setStartDate={setStartDate}
            />
            <CalendarZoomer setShowDays={setShowDays} />
          </div>
        </div>
        <div className="row border-bottom">
          <div className="col-2 calendar--timeline border-right p-0">
            <CalendarSearch clearSearchPath={clearSearchPath} />
          </div>
          <div className="col-10 text-nowrap p-0">
            <DateRange
              interval={intervalFromDates(interval)}
              dayWidth={dayWidth}
              showDays={showDays}
            />
          </div>
        </div>
      </form>
      {constructionManagers.map((constructionManager) => {
        return (
          <ConstructionManagerProjects
            key={constructionManager.id}
            dayWidth={Number(dayWidth)}
            constructionManager={constructionManager}
            setSelectedProject={setSelectedProject}
            showDays={Boolean(showDays)}
            calendarInterval={interval}
          />
        )
      })}
      <Sidebar
        visible={selectedProject.state === "open"}
        onDismiss={() => setSelectedProject({ state: "closed", project: null })}
      >
        {selectedProject.project ? (
          <ProjectDetails
            project={selectedProject.project}
            canEditProjects={canEditProjects}
            calendarType="construction_managers"
            editParams={props.projectEditParams}
          />
        ) : (
          <></>
        )}
      </Sidebar>
    </div>
  )
}

export default ConstructionManagersCalendar
