import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./index.scss"

const DateRange = (props) => {
  const { interval, dayWidth, showDays } = props
  const months = interval.months()
  const weeks = interval.weeks()

  const [fadeText, setFadeText] = useState(false)

  useEffect(() => {
    setFadeText(true)
    const timer = setTimeout(() => {
      setFadeText(false)
    }, 500)
    return () => clearTimeout(timer)
  }, [interval])

  const fadeClass = fadeText ? "text-fade" : ""

  return (
    <div className={"calendar--timeline text-nowrap p-0 "}>
      <div className="months">
        {months &&
          !showDays &&
          months.map((month, index) => (
            <div
              key={index}
              className="month d-inline-block border-bottom border-right text-center"
              style={{ width: dayWidth * month.days.length + "px" }}
            >
              {dayWidth * month.days.length > 60 ? (
                <span className={fadeClass}>
                  {month.monthShort} {String(month.year).substring(2)}
                </span>
              ) : (
                <div className="month--placeholder d-inline-block"> </div>
              )}
            </div>
          ))}
      </div>
      <div className="weeks">
        {weeks &&
          weeks.map((week, index) => (
            <div
              key={index}
              className="week"
              style={{ width: dayWidth * week.days.length + "px" }}
            >
              <span className={`month-names ${fadeClass}`}>
                {showDays &&
                  week.monthNames + " " + String(week.year).substring(2)}
              </span>
              <span
                className={`week-number " ${
                  showDays ? "days-active" : ""
                }   ${fadeClass}`}
                aria-label={`Uge ${week.weekNumber}`}
              >
                {week.weekNumber}
              </span>
              <br />
              {showDays &&
                week.days.map((day, index) => (
                  <div
                    key={index}
                    className={`day ${
                      day.weekday > 5 ? "weekend" : ""
                    }  ${fadeClass}`}
                    style={{ width: dayWidth }}
                  >
                    {day.day}
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  )
}

DateRange.propTypes = {
  dayWidth: PropTypes.number.isRequired,
  interval: PropTypes.object.isRequired,
  showDays: PropTypes.bool,
}

export default DateRange
