import React from "react"
import PropTypes from "prop-types"

export default function CalendarExpandAll(props) {
  const { setExpandAll, className } = props

  function handleOnChange({ target }) {
    setExpandAll(target.value)
  }

  return (
    <select
      style={{ width: "205px" }}
      onChange={handleOnChange}
      className={`${className} custom-select border-none font-weight-bold text-primary`}
      aria-label="Fold projekter ud/ind"
    >
      <option value="">Fold projekter ud/ind</option>
      <option value={0}>Fold alle projekter ind</option>
      <option value={1}>Fold projekter ud</option>
      <option value={2}>Fold ressourcegrupper ud</option>
    </select>
  )
}

CalendarExpandAll.propTypes = {
  className: PropTypes.string,
  setExpandAll: PropTypes.func,
}
