import React from "react"
import { Interval } from "luxon"
import { createAbsences } from "../CalendarProjectResource"
import ResourceCalendarAbsence from "../ResourceCalendarAbsence"

const AbsenceTimeLine = ({
  absences,
  calendarInterval,
  dayWidth,
  showDays,
}: {
  absences: any
  calendarInterval: Interval
  dayWidth: number
  showDays: boolean
}) => {
  const { absenceGroupedByOffsetMargin, absenceWeeks } = createAbsences(
    absences,
    calendarInterval,
    dayWidth
  )

  return (
    <div>
      {showDays &&
        absenceGroupedByOffsetMargin.map((absence, index) => (
          <ResourceCalendarAbsence
            key={index}
            width={dayWidth}
            top={5}
            offsetMargin={absence.offsetMargin}
            approved={absence.approved}
            marginTop={4}
          />
        ))}
      {!showDays &&
        absenceWeeks.map((absenceWeek, index) => (
          <ResourceCalendarAbsence
            key={index}
            top={5}
            width={dayWidth * 7}
            offsetMargin={absenceWeek.offsetMargin}
            marginTop={4}
            approved={absenceWeek.approved}
          />
        ))}
    </div>
  )
}

export default AbsenceTimeLine
