import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import "./index.scss"
import { DateTime } from "luxon"

function ProjectEndDate(props) {
  const { durationInputSelector, startDate } = props

  const durationInput = document.querySelector(durationInputSelector)

  const [durationInDays, setDurationInDays] = useState(
    parseInt(durationInput.value)
  )

  useEffect(() => {
    let durationListener = (event) => {
      setDurationInDays(parseInt(event.target.value))
    }
    durationInput.addEventListener("change", durationListener)

    return () => {
      durationInput.removeEventListener("change", durationListener)
    }
  })

  // when startDate is empty, it does not throw when trying to plus days: NaN.
  const start = DateTime.fromISO(durationInDays ? startDate : "")
  const end = start.plus({ days: durationInDays - 1 })

  return (
    <>
      <label htmlFor="project_end_date">Slut dato</label>
      <br />
      <input
        value={end.toISODate() || ""}
        className="form-control"
        disabled="disabled"
        type="date"
        name="project[end_date]"
        id="project_end_date"
      ></input>
    </>
  )
}

ProjectEndDate.propTypes = {
  durationInputSelector: PropTypes.string.isRequired,
  startDate: PropTypes.any,
}

export default ProjectEndDate
