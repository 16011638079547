import React from "react"
import PropTypes from "prop-types"
import "./index.scss"
import ProjectBookings from "../ProjectBookings"
import useBookings from "../../hooks/useBookings"
import { Button } from "react-bootstrap"
import shadeColor from "../../utils/shadeColor"

function ProjectResource(props) {
  const {
    handleRemoveResource,
    parentInputFieldPrefix,
    projectResource,
    resourceIndex,
    resourceGroup,
  } = props

  const resourceable = projectResource.resourceable
  const name = resourceable.deletedAt
    ? "Slettet medarbejder"
    : resourceable.name
  const inputFieldPrefix = `${parentInputFieldPrefix}[project_resources_attributes][${resourceIndex}]`

  const { bookings, setBookings } = useBookings(projectResource.bookings)

  return (
    <>
      <div
        className={`col-2 justify-content-between ${
          projectResource.destroy ? "d-none" : "d-flex"
        }`}
      >
        <div className="text-truncate">{name}</div>
        <Button
          onClick={() => {
            handleRemoveResource(resourceIndex)
          }}
          variant=""
          size="sm"
          className="text-danger p-0"
          aria-label={`Fjern ${resourceable.name}`}
        >
          Slet
        </Button>
      </div>
      <div
        className={`col-10 resource--booking text-nowrap p-0 ${
          projectResource.destroy ? "d-none" : ""
        }`}
      >
        <input
          type="hidden"
          name={`${inputFieldPrefix}[id]`}
          value={projectResource.id}
        />
        <input
          type="hidden"
          name={`${inputFieldPrefix}[resourceable_id]`}
          value={projectResource.resourceable.id}
        />
        <input
          type="hidden"
          name={`${inputFieldPrefix}[resourceable_type]`}
          value={projectResource.resourceableType}
        />
        <input
          type="hidden"
          name={`${inputFieldPrefix}[_destroy]`}
          value={projectResource.destroy || "false"}
        />
        <ProjectBookings
          bookings={bookings}
          setBookings={setBookings}
          color={resourceable.color || shadeColor(resourceGroup.color, 20)}
          inputFieldPrefix={inputFieldPrefix}
        />
      </div>
    </>
  )
}

ProjectResource.propTypes = {
  handleRemoveResource: PropTypes.func,
  parentInputFieldPrefix: PropTypes.string,
  projectResource: PropTypes.shape({
    destroy: PropTypes.bool,
    bookings: PropTypes.arrayOf(
      PropTypes.shape({
        offsetInDays: PropTypes.number,
      })
    ),
    id: PropTypes.number,
    resourceable: PropTypes.shape({
      color: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      deletedAt: PropTypes.string,
    }).isRequired,
    resourceableType: PropTypes.string,
  }),
  resourceIndex: PropTypes.any,
  resourceGroup: PropTypes.shape({
    color: PropTypes.string,
  }),
}

export default ProjectResource
