import React from "react"
import whiteCheckmark from "../../images/white-checkmark.svg"

type Props = {
  setShowArchived: (value: boolean) => void
  className: string
  value: boolean
}

export default function ResourceShowArchivedProjects(props: Props) {
  const { setShowArchived, value } = props

  function handleOnChange(value: boolean) {
    setShowArchived(value)
  }

  return (
    <div className="d-flex align-items-center justify-content-center calendar-archived-projects ml-4">
      <div
        data-testid="showArchived"
        className={`calendar-archived-check d-flex align-items-center justify-content-center ${
          value ? "checked" : ""
        }`}
        onClick={() => handleOnChange(!value)}
      >
        {value && (
          <img
            src={whiteCheckmark}
            id="archivedCheck"
            style={{ width: "20px", height: "20px" }}
          />
        )}
      </div>
      <label onClick={() => handleOnChange(!value)} htmlFor="archivedCheck">
        Vis arkiverede projekter
      </label>
    </div>
  )
}
