import React, { useEffect, useState } from "react"
import ProjectLink from "../ProjectLink"
import "./index.scss"

export type ProjectLink = {
  id?: number
  title?: string
  url?: string
  destroy?: boolean
}

const ProjectLinks = ({ projectLinks }: { projectLinks: ProjectLink[] }) => {
  const [links, setLinks] = useState<ProjectLink[]>(projectLinks)

  const addBuisnessCentralLink = () => {
    if (
      projectLinks.filter((link) => link.title === "Business Central")
        .length === 0
    ) {
      setLinks((links) => [...links, { title: "Business Central", url: "" }])
    }
  }

  const addSharePointLink = () => {
    if (
      projectLinks.filter((link) => link.title === "SharePoint").length === 0
    ) {
      setLinks((links) => [...links, { title: "SharePoint", url: "" }])
    }
  }

  const handleAddProjectLink = (value: ProjectLink) => {
    setLinks([...links, {}])
  }

  const handleRemoveProjectLink = (index: number) => {
    const newLinks = [...links]
    newLinks[index].destroy = true
    setLinks(newLinks)
  }

  useEffect(() => {
    addBuisnessCentralLink()
    addSharePointLink()
  }, [])

  return (
    <div className="row">
      <div className="project-links-grid">
        {links.map((link, index) => {
          return (
            <ProjectLink
              index={index}
              key={index}
              projectLink={link}
              handleRemoveProjectLink={handleRemoveProjectLink}
            />
          )
        })}
        <button
          type="button"
          className="btn  btn-outline-success"
          onClick={() => {
            handleAddProjectLink({})
          }}
        >
          Tilføj nyt link
        </button>
      </div>
    </div>
  )
}

export default ProjectLinks
