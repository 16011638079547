import PropTypes from "prop-types"
import React from "react"

export default function CalendarCategoryFilter(props) {
  const { categories, className, formRef } = props

  const urlParams = new URLSearchParams(window.location.search)
  const query = urlParams.get("q[g][0][category_eq]") || ""

  function handleOnChange() {
    formRef.current.submit()
  }

  return (
    <select
      name="q[g][0][category_eq]"
      onChange={handleOnChange}
      className={`${className} custom-select font-weight-bold bg-transparent text-primary`}
      aria-label="Filter by category"
      value={query}
    >
      <option value="">Filtre</option>
      {categories.map((category) => (
        <option key={category} value={category}>
          {category}
        </option>
      ))}
    </select>
  )
}

CalendarCategoryFilter.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array,
  formRef: PropTypes.shape({
    current: PropTypes.shape({
      submit: PropTypes.func,
    }),
  }),
}
