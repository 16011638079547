import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import expand from "../../images/expand.svg"
import collapse from "../../images/collapse.svg"
import { Overlay, Tooltip } from "react-bootstrap"

import "./index.scss"
export default function ResourceCalendarExpandAll(props) {
  const { setExpandAll, value } = props

  function handleOnChange(value) {
    setExpandAll(value)
  }

  const ref = useRef()
  const [showMessage, setShowMessage] = useState(false)

  return (
    <div
      data-testid="expand"
      className="expand-button d-flex align-items-center justify-content-center"
      ref={ref}
      onClick={() => handleOnChange(value == 0 ? 1 : 0)}
      onMouseOver={() => {
        setShowMessage(true)
      }}
      onMouseOut={() => {
        setShowMessage(false)
      }}
    >
      <img
        src={value == 0 ? expand : collapse}
        style={{ width: "30px", height: "30px" }}
      />
      <Overlay
        target={ref}
        show={showMessage}
        transition={false}
        placement="bottom"
      >
        <Tooltip>
          {value == 0 ? "Fold all ressourcer ud" : "Kollaps alle ressourcer"}
        </Tooltip>
      </Overlay>
    </div>
  )
}

ResourceCalendarExpandAll.propTypes = {
  className: PropTypes.string,
  setExpandAll: PropTypes.func,
  value: PropTypes.number,
}
