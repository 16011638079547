export default function intervalFromOffsets(firstOffsetShown, lastOffsetShown) {
  const months = () => []
  const weeks = () => calculateWeeks(firstOffsetShown, lastOffsetShown)
  return { months, weeks }
}

function calculateWeeks(firstOffsetShown, lastOffsetShown) {
  const weeks = []

  for (let offset = firstOffsetShown; offset <= lastOffsetShown; offset += 7) {
    const weekNumber = Math.floor(offset / 7) + 1
    weeks.push({
      weekNumber: weekNumber,
      monthNames: "",
      days: makeDays(offset, 7),
    })
  }

  const daysInLastWeek = ((lastOffsetShown - firstOffsetShown) % 7) + 1

  weeks[weeks.length - 1].days = makeDays(
    (weeks.length - 1) * 7,
    daysInLastWeek
  )

  return weeks
}

function makeDays(first, amount) {
  const days = []
  for (let i = first; i < first + amount; i++) {
    days.push({ weekday: (i % 7) + 1, day: i + 1 }) // magic
  }

  return days
}
