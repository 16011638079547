import { useEffect, useState, useContext } from "react"
import { ProjectBookerContext } from "../contexts/ProjectBookerContext"

export default function useBookings(bookingsFromBackend) {
  const { durationInDays } = useContext(ProjectBookerContext)

  const [bookings, setBookings] = useState(() =>
    createBookings(
      bookingsFromBackend.sort((a, b) => a.offsetInDays - b.offsetInDays)
    )
  )

  useEffect(() => {
    setBookings(createBookings(bookings))
  }, [durationInDays])

  function createBookings(bookings) {
    if (bookings.length >= durationInDays) {
      return bookings
    }

    const bookingsByDay = bookings.reduce((result, booking) => {
      result[booking.offsetInDays] = booking
      return result
    }, {})

    return Array.from(Array(durationInDays).keys()).map((day) => {
      return bookingsByDay[day] || { offsetInDays: day, destroy: true }
    })
  }

  return { bookings, setBookings }
}
