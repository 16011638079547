import React, { useState } from "react"
import "./index.scss"
import clearSearch from "../../images/clear-search.svg"

function CalendarSearch({ clearSearchPath }: { clearSearchPath: string }) {
  const urlParams = new URLSearchParams(window.location.search)
  const [query, setQuery] = useState(urlParams.get("q[g][1][id_eq]") || "")

  // TODO: Rename project_search so it makes sense for both projects and resources

  return (
    <div className="input-group position-relative">
      <input
        type="text"
        className="form-control"
        id="project_search"
        name="q[g][1][project_manager_or_name_or_construction_manager_initials_or_construction_manager_name_or_crew_name_or_start]"
        value={query}
        placeholder="Søg.."
        onChange={(e) => setQuery(e.target.value)}
      />
      <input type="hidden" name="q[g][1][m]" id="project_or" value="or" />
      <input
        type="hidden"
        name="q[g][1][id_eq]"
        id="project_id_eq"
        value={query}
      />
      <a href={clearSearchPath} className="position-absolute clear--search">
        <img src={clearSearch} style={{ width: "20px", height: "20px" }} />
      </a>

      <div className="input-group-append" id="search-button">
        <button
          type="submit"
          className="btn btn-primary rounded-0"
          value="Search"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default CalendarSearch
