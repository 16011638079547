export default function intervalFromDates(interval) {
  const months = () => calculateMonths(interval)
  const weeks = () => calculateWeeks(interval)

  return { months, weeks }
}

const calculateWeeks = (interval) => {
  const rewoundStart = interval.start.startOf("week")
  const weekIntervals = interval
    .set({ start: rewoundStart })
    .splitBy({ weeks: 1 })
  weekIntervals[0] = weekIntervals[0].set({ start: interval.start })

  return weekIntervals.map((weekInterval) => {
    let endOfWeek = weekInterval.end
    let month = weekInterval.start.monthShort
    let year = weekInterval.start.year

    if (month != endOfWeek.monthShort) {
      month += "-" + endOfWeek.monthShort
    }

    return {
      days: intervalToDays(weekInterval),
      monthNames: month,
      weekNumber: weekInterval.start.weekNumber,
      year: year,
    }
  })
}

const calculateMonths = (interval) => {
  const rewoundStart = interval.start.startOf("month")
  const monthIntervals = interval
    .set({ start: rewoundStart })
    .splitBy({ months: 1 })
  monthIntervals[0] = monthIntervals[0].set({ start: interval.start })

  return monthIntervals.map((monthInterval) => {
    return {
      days: intervalToDays(monthInterval),
      monthNumber: monthInterval.start.month,
      monthShort: monthInterval.start.monthShort,
      year: monthInterval.start.year,
    }
  })
}

const intervalToDays = (interval) => {
  return interval.splitBy({ days: 1 }).map((interval) => interval.start)
}
