import React from "react"
import PropTypes from "prop-types"

export default function CalendarBooking(props) {
  const { offsetMargin, className, dayWidth, color, showDays, children } = props
  return (
    <div
      className={className}
      style={{
        position: "absolute",
        width: dayWidth,
        backgroundColor: color,
        left: offsetMargin,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {showDays && children}
    </div>
  )
}

CalendarBooking.propTypes = {
  offsetMargin: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  dayWidth: PropTypes.number,
  color: PropTypes.string,
  showDays: PropTypes.bool,
}
