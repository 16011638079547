import React from "react"
import PropTypes from "prop-types"
import shadeColor from "../../utils/shadeColor"
import Warning from "../Warning"
import "./index.scss"
import CalendarBooking from "../CalendarBooking"
import ResourceCalendarDoubleBooked from "../ResourceCalendarDoubleBooked"
import { DateTime } from "luxon"
import ResourceCalendarAbsence from "../ResourceCalendarAbsence"

function CalendarProjectResource(props) {
  const {
    dayWidth,
    showDays,
    offsetMargin,
    projectResource,
    color,
    calendarInterval,
    doubleBookings,
  } = props
  const resource = projectResource.resourceable
  const bookings = projectResource.bookings

  const myDoubleBookings = (doubleBookings || []).filter(
    (doubleBooking) => doubleBooking["resourceableId"] == resource.id
  )
  const doubleBooked = myDoubleBookings.length > 0
  const warningMessage = doubleBooked
    ? `Ressourcen er dobbelt booket, første den ${myDoubleBookings[0]["doubleBookedDate"]}`
    : null

  if (bookings.length == 0) {
    return null
  }

  const name = resource.deletedAt ? "Slettet medarbejder" : resource.name

  function showDoubleBooking(booking) {
    return myDoubleBookings.some(
      (doubleBooking) => doubleBooking["bookingId"] == booking.id
    )
  }
  const { absenceGroupedByOffsetMargin, absenceWeeks } = createAbsences(
    resource.futureAbsences,
    calendarInterval,
    dayWidth
  )

  return (
    <>
      <div className="col-2 border-right text-right resource--name p-2">
        {warningMessage && <Warning message={warningMessage} />}
        {name}
      </div>
      <div className="col-10 p-0 booking-timeline">
        <div
          style={{ marginLeft: offsetMargin, position: "relative" }}
          className={`calendar-resource ${resource.name}`}
        >
          {bookings.map((booking) => (
            <CalendarBooking
              key={booking.id}
              offsetMargin={booking.offsetInDays * dayWidth}
              className="project-resource--booking"
              dayWidth={dayWidth}
              showDays={showDays}
              color={shadeColor(color, 20)}
            >
              {showDoubleBooking(booking) && <ResourceCalendarDoubleBooked />}
            </CalendarBooking>
          ))}
        </div>
        <div>
          {showDays &&
            absenceGroupedByOffsetMargin.map((absence, index) => (
              <ResourceCalendarAbsence
                key={index}
                width={dayWidth}
                offsetMargin={absence.offsetMargin}
                approved={absence.approved}
                marginTop={4}
              />
            ))}
          {!showDays &&
            absenceWeeks.map((absenceWeek, index) => (
              <ResourceCalendarAbsence
                key={index}
                width={dayWidth * 7}
                offsetMargin={absenceWeek.offsetMargin}
                marginTop={4}
                approved={absenceWeek.approved}
              />
            ))}
        </div>
      </div>
    </>
  )
}

export function createAbsence(absenceFromDB, calendarInterval, dayWidth) {
  const startDate = DateTime.fromISO(absenceFromDB.startDate)
  const offsetInDays = Math.ceil(
    startDate.diff(calendarInterval.start).as("days")
  )
  const offsetMargin = offsetInDays * dayWidth
  return { ...absenceFromDB, offsetMargin, startDate, offsetInDays }
}

export function createAbsences(absencesFromDB, calendarInterval, dayWidth) {
  let absenceGroupedByOffsetMargin = []
  let absenceWeeks = []

  absencesFromDB.forEach((absenceFromDB) => {
    const absence = createAbsence(absenceFromDB, calendarInterval, dayWidth)
    absenceGroupedByOffsetMargin[absence.offsetMargin] ||= []
    absenceGroupedByOffsetMargin[absence.offsetMargin] = absence

    const weekOffset = absence.startDate
      .startOf("week")
      .diff(calendarInterval.start)
      .as("days")
    const offsetMargin = weekOffset * dayWidth

    let index = absence.startDate.year + "" + absence.startDate.weekNumber

    absenceWeeks[index] ||= { offsetMargin, approved: absence.approved }
  })

  return { absenceGroupedByOffsetMargin, absenceWeeks }
}

CalendarProjectResource.propTypes = {
  doubleBookings: PropTypes.array,
  color: PropTypes.string,
  dayWidth: PropTypes.number.isRequired,
  showDays: PropTypes.bool,
  calendarInterval: PropTypes.object,
  offsetMargin: PropTypes.number.isRequired,
  projectResource: PropTypes.shape({
    resourceable: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      futureAbsences: PropTypes.array,
      deletedAt: PropTypes.string,
    }).isRequired,
    bookings: PropTypes.array,
  }).isRequired,
}

export default CalendarProjectResource
