import { useMutation, useQueryClient } from "react-query"
import { User } from "../components/Users"

export const useUpdateUser = () => {
  // Get QueryClient from the context
  const queryClient = useQueryClient()

  return useMutation(
    // You can destructure the argument here if you prefer
    ({ userId, name, role }: { userId: number; name: string; role: string }) =>
      updateUser(userId, name, role),
    {
      // Optional configurations such as onError, onSuccess, etc.
      onSuccess: () => {
        queryClient.invalidateQueries("users")
      },
      onError: (error: any) => {
        console.error("There was an error updating the user:", error)
      },
    }
  )
}

export const useCreateUser = () => {
  // Get QueryClient from the context
  const queryClient = useQueryClient()

  return useMutation(
    // You can destructure the argument here if you prefer
    ({
      name,
      role,
      password,
      email,
    }: {
      name: string
      role: string
      password: string
      email: string
    }) => createUser(email, password, name, role),
    {
      // Optional configurations such as onError, onSuccess, etc.
      onSuccess: () => {
        queryClient.invalidateQueries("users")
      },
      onError: (error: any) => {
        console.error("There was an error updating the user:", error)
      },
    }
  )
}

async function updateUser(
  userId: number,
  name: string,
  role: string
): Promise<any> {
  const url = `/api/v1/users/${userId}`
  const body = {
    user: {
      name,
      role,
    },
  }

  try {
    const response = await fetch(url, {
      method: "PATCH", // Rails typically uses PATCH for update actions
      headers: {
        "Content-Type": "application/json",
        // Add any other headers you might need, like authentication headers
      },
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(`Error updating user: ${errorData}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("There was an error updating the user:", error)
    throw error
  }
}

export const useDeleteUser = () => {
  // Get QueryClient from the context
  const queryClient = useQueryClient()

  return useMutation(
    // You can destructure the argument here if you prefer
    (userId: number) => deleteUser(userId),
    {
      // Optional configurations such as onError, onSuccess, etc.
      onSuccess: () => {
        queryClient.invalidateQueries("users")
      },
      onError: (error: any) => {
        console.error("There was an error deleting the user:", error)
      },
    }
  )
}

async function deleteUser(userId: number): Promise<any> {
  const url = `/api/v1/users/${userId}`

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers you might need, like authentication headers
      },
    })

    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(`Error deleting user: ${errorData}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("There was an error deleting the user:", error)
    throw error
  }
}

async function createUser(
  email: string,
  password: string,
  name: string,
  role: string
): Promise<any> {
  const url = `/api/v1/users`
  const body = {
    user: {
      name,
      role,
      email,
      password,
    },
  }

  try {
    const response = await fetch(url, {
      method: "POST", // Rails typically uses PATCH for update actions
      headers: {
        "Content-Type": "application/json",
        // Add any other headers you might need, like authentication headers
      },
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(`Error creating user: ${errorData}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("There was an error updating the user:", error)
    throw error
  }
}
