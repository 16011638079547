import React, {
  useState,
  useRef,
  useLayoutEffect,
  useMemo,
  useEffect,
} from "react"
import PropTypes from "prop-types"
import { DateTime, Interval } from "luxon"
import DateRange from "../DateRange"
import DateHopper from "../DateHopper"
import CalendarZoomer from "../CalendarZoomer"
import Grid from "../Grid"
import CalendarSearch from "../CalendarSearch"
import intervalFromDates from "../../utils/intervalFromDates"
import ResourceCalendarResource from "../ResourceCalendarResource"
import ResourceCalendarExpandAll from "../ResourceCalendarExpandAll"
import ResourceShowArchivedProjects from "../ResourceShowArchivedProjects"
import ResourceCalendarGroupFilter from "../ResourceCalendarGroupFilter"
import useShowDays from "../../hooks/useShowDays"
import camelizeProps from "../../utils/camelizeProps"
import Sidebar from "../Sidebar"
import ProjectDetails from "../ProjectDetails"

export default function ResourceCalendar(props) {
  const {
    resourcesFromDB,
    clearSearchPath,
    resourceGroupsFromDb,
    canEditProjects,
    projectEditParams,
  } = props
  const resourceGroups = camelizeProps(resourceGroupsFromDb)
  const [resources, setResources] = useState(camelizeProps(resourcesFromDB))
  const [dayWidth, showDays, setShowDays] = useShowDays(false)
  const [startDate, setStartDate] = useState(DateTime.local())
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [showArchived, setShowArchived] = useState(false)
  const [expandAll, setExpandAll] = useState(0)
  const [selectedProject, setSelectedProject] = useState({
    state: "closed",
    project: null,
  })

  const formRef = useRef()

  useEffect(() => {
    let updatedResources = null
    if (expandAll == 0) {
      updatedResources = resources.map((resource) => {
        return { ...resource, expanded: false }
      })
    } else {
      updatedResources = resources.map((resource) => {
        return { ...resource, expanded: true }
      })
    }
    setResources(updatedResources)
  }, [expandAll])

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  const daysToShow = Math.floor(windowWidth / dayWidth)

  const interval = useMemo(() => {
    let start = DateTime.fromISO(startDate).startOf("week")
    let end = start.plus({ days: daysToShow }).endOf("week").endOf("day")
    return Interval.fromDateTimes(start, end)
  }, [startDate, daysToShow])

  return (
    <div id="calendar">
      <div className="row">
        <div className="col-2 border-right"></div>
        <div className="col-10 pl-0 position-unset">
          <Grid
            interval={intervalFromDates(interval)}
            dayWidth={dayWidth}
            showDays={showDays}
          />
        </div>
      </div>
      <form ref={formRef} className="sticky-top">
        <div className="row p-2 px-4 border-bottom bg-white align-items-center d-flex justify-content-between height-top-bars calendar-actions">
          <div className="d-flex justify-content-start align-items-center calendar-actions-start">
            <div className="d-flex calendar-actions-links">
              <a
                type="button"
                className="d-flex align-items-center"
                href="/projects"
              >
                Projekter
              </a>
              <a className="d-flex align-items-center active" href="/resources">
                Håndværkere
              </a>
              <a
                className="d-flex align-items-center "
                href="/construction-managers"
              >
                Administration
              </a>
            </div>
            <div className="d-flex calendar-actions-filters">
              <ResourceCalendarExpandAll
                setExpandAll={setExpandAll}
                value={expandAll}
              />
              <ResourceCalendarGroupFilter
                groups={resourceGroups}
                formRef={formRef}
              />
              <ResourceShowArchivedProjects
                setShowArchived={setShowArchived}
                value={showArchived}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end align-items-center">
            <DateHopper
              className="mr-3"
              startDate={startDate}
              setStartDate={setStartDate}
            />
            <CalendarZoomer setShowDays={setShowDays} />
          </div>
        </div>
        <div className="row border-bottom">
          <div className="col-2 calendar--timeline border-right p-0">
            <CalendarSearch clearSearchPath={clearSearchPath} />
          </div>
          <div className="col-10 text-nowrap p-0">
            <DateRange
              interval={intervalFromDates(interval)}
              dayWidth={dayWidth}
              showDays={showDays}
            />
          </div>
        </div>
      </form>

      {resources.map((resource, index) => (
        <ResourceCalendarResource
          key={resource.id}
          setSelectedProject={setSelectedProject}
          dayWidth={dayWidth}
          showArchived={showArchived}
          showDays={showDays}
          canEditProjects={canEditProjects}
          resource={resource}
          calendarInterval={interval}
          setResource={(updatedResource) => {
            const updatedResources = [...resources]
            updatedResources[index] = updatedResource
            setResources(updatedResources)
          }}
          projectEditParams={projectEditParams}
        />
      ))}
      <Sidebar
        visible={selectedProject.state === "open"}
        onDismiss={() => setSelectedProject({ state: "closed", project: null })}
      >
        {selectedProject.project ? (
          <ProjectDetails
            project={selectedProject.project}
            editParams={projectEditParams}
            canEditProjects={canEditProjects}
            calendarType="resources"
          />
        ) : (
          <></>
        )}
      </Sidebar>
    </div>
  )
}

ResourceCalendar.propTypes = {
  clearSearchPath: PropTypes.string,
  canEditProjects: PropTypes.bool,
  resourcesFromDB: PropTypes.array,
  resourceGroupsFromDb: PropTypes.array,
  projectEditParams: PropTypes.string,
}
