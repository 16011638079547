import React, { useContext } from "react"
import PropTypes from "prop-types"
import { ProjectBookerContext } from "../../contexts/ProjectBookerContext"

export default function ProjectWeekBooking(props) {
  const { week, onClick, onMouseOver } = props

  const { dayWidth } = useContext(ProjectBookerContext)

  return (
    <div
      style={{ width: dayWidth * week.days.length }}
      className="booking d-inline-block"
      onClick={() => onClick(week)}
      onMouseOver={() => onMouseOver(week)}
      aria-label={`Book uge ${week.weekNumber}`}
    ></div>
  )
}

ProjectWeekBooking.propTypes = {
  week: PropTypes.shape({
    days: PropTypes.array,
    weekNumber: PropTypes.number,
  }),
  onMouseOver: PropTypes.func,
  onClick: PropTypes.func,
}
