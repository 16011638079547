import React from "react"
import PropTypes from "prop-types"
import shadeColor from "../../utils/shadeColor"
import { DateTime, Interval } from "luxon"
import Warning from "../Warning"
import CalendarBooking from "../CalendarBooking"
import ResourceCalendarAbsence from "../ResourceCalendarAbsence"
import ResourceCalendarAbsenceWarning from "../ResourceCalendarAbsenceWarning"
import ResourceCalendarDoubleBooked from "../ResourceCalendarDoubleBooked"
import "./index.scss"

export default function ResourceCalendarResource(props) {
  const {
    resource,
    dayWidth,
    calendarInterval,
    setResource,
    canEditProjects,
    showDays,
    projectEditParams,
    showArchived,
    setSelectedProject,
  } = props

  const formattedEditParams = projectEditParams ? `&${projectEditParams}` : ""

  const { absenceGroupedByOffsetMargin, absenceWeeks } = createAbsences(
    resource.futureAbsences,
    calendarInterval,
    dayWidth
  )

  let projects = resource.projectResources
    .map((projectResource) => {
      let project = projectResource.projectResourceGroup.project

      if (!showArchived && project.archived) {
        return false
      }

      project = {
        ...project,
        offsetMargin: daysUntilStart(calendarInterval, project) * dayWidth,
      }

      const bookings = projectResource.bookings
        .map((booking) => {
          return {
            ...booking,
            offsetMargin:
              booking.offsetInDays * dayWidth + project.offsetMargin,
            estimate: !project.startDate,
          }
        })
        .filter((booking) => booking.offsetMargin >= 0)
      return { ...project, bookings }
    })
    .filter(Boolean)
    .filter((project) => {
      const now = DateTime.now()
      const cachedEndDate = DateTime.fromISO(project.cachedEndDate)
      const diffInDays = now.diff(cachedEndDate, "days").days
      return diffInDays < 30
    })

  const groupedBookings = groupNonEstimateBookingsByOffsetMargin(projects)

  const absenceDaysWithBooking = absenceGroupedByOffsetMargin.filter(
    (k, index) => {
      if (groupedBookings[index] != undefined) {
        return true
      }
    }
  )

  const warningMessage =
    groupedBookings.some(
      (bookingsOnOffsetMargin) => bookingsOnOffsetMargin.length > 1
    ) && "Der er dobbelt bookings"

  projects = projects.map((project) => {
    project.bookings = project.bookings.map((booking) => {
      return {
        ...booking,
        doubleBooked:
          !booking.estimate && groupedBookings[booking.offsetMargin].length > 1,
      }
    })
    const warningMessage =
      project.bookings.some((booking) => booking.doubleBooked) &&
      "Der er dobbelt bookings"
    return { ...project, warningMessage }
  })

  return (
    <div className="row border-bottom">
      <div className="col-2 resource--name col-2 border-right p-2 text-left">
        <div
          className="d-flex expand-resource justify-content-between"
          onClick={() =>
            setResource({ ...resource, expanded: !resource.expanded })
          }
        >
          <div className="text-truncate w-75 ">
            {resource.crew && (
              <>
                <span className="font-weight-bold">{resource.crew?.name}</span>
                <span className="font-weight-bold">{" - "}</span>
              </>
            )}
            <span>{resource.name}</span>
          </div>

          <span>
            {absenceDaysWithBooking.length != 0 && (
              <ResourceCalendarAbsenceWarning
                message={"Feriedag med booking"}
              />
            )}
            {warningMessage && <Warning message={warningMessage} />}
            {projects.length > 0 && (
              <span>{resource.expanded ? "-" : "+"}</span>
            )}
          </span>
        </div>
      </div>
      <div className="col-10 py-1 px-0">
        <div style={{ position: "relative" }}>
          {projects.map(({ bookings }) =>
            bookings.map((booking) => (
              <CalendarBooking
                key={booking.offsetMargin}
                offsetMargin={booking.offsetMargin}
                className="project-resource--booking"
                dayWidth={dayWidth}
                color={resource.resourceGroup.color}
                showDays={showDays}
              />
            ))
          )}
          {showDays &&
            absenceGroupedByOffsetMargin.map((absence, index) => (
              <ResourceCalendarAbsence
                key={index}
                width={dayWidth}
                offsetMargin={absence.offsetMargin}
                approved={absence.approved}
                marginTop={1}
              />
            ))}
          {!showDays &&
            absenceWeeks.map((absenceWeek, index) => (
              <ResourceCalendarAbsence
                key={index}
                width={dayWidth * 7}
                offsetMargin={absenceWeek.offsetMargin}
                marginTop={1}
                approved={absenceWeek.approved}
              />
            ))}
        </div>
      </div>
      {resource.expanded &&
        projects.map((project, index) => (
          <React.Fragment key={index}>
            <div className="col-2  p-2 border-right resource--project justify-content-between d-flex align-items-center">
              <button
                type="button"
                className="btn btn-outline-dark btn-sm border-dark text-primary"
                onClick={() =>
                  setSelectedProject({ state: "open", project: project })
                }
              >
                Detaljer
              </button>
              {canEditProjects ? (
                <a
                  className="text-right"
                  style={{ fontSize: 12 }}
                  href={`/projects/${project.id}/edit?calendar_type=resources${formattedEditParams}`}
                  data-testid={project.name}
                >
                  {project.warningMessage && (
                    <Warning message={project.warningMessage} />
                  )}
                  {project.id} {project.name}
                </a>
              ) : (
                <span
                  className=" text-right p-2 project--name"
                  style={{ fontSize: 12 }}
                  disabled={true}
                  data-testid={project.name}
                >
                  {project.warningMessage && (
                    <Warning message={project.warningMessage} />
                  )}
                  {project.id} {project.name}
                </span>
              )}
            </div>

            <div className="col-10 py-2 px-0 booking-timeline">
              <div style={{ position: "relative" }}>
                {project.bookings.map((booking, index) => (
                  <React.Fragment key={index}>
                    <CalendarBooking
                      showDays={showDays}
                      offsetMargin={booking.offsetMargin}
                      color={
                        booking.estimate
                          ? ""
                          : shadeColor(resource.resourceGroup.color, 20)
                      }
                      className={`${
                        booking.estimate ? "estimate" : ""
                      } project-resource--booking`}
                      dayWidth={dayWidth}
                    >
                      {absenceGroupedByOffsetMargin[booking.offsetMargin] && (
                        <ResourceCalendarAbsence
                          approved={
                            absenceGroupedByOffsetMargin[booking.offsetMargin]
                              .approved
                          }
                          width={dayWidth}
                          marginTop={0}
                        />
                      )}
                      {booking.doubleBooked && <ResourceCalendarDoubleBooked />}
                    </CalendarBooking>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </React.Fragment>
        ))}
    </div>
  )
}

ResourceCalendarResource.propTypes = {
  calendarInterval: PropTypes.instanceOf(Interval),
  dayWidth: PropTypes.number,
  showDays: PropTypes.bool,
  canEditProjects: PropTypes.bool,
  setSelectedProject: PropTypes.func,
  resource: PropTypes.shape({
    id: PropTypes.number,
    expanded: PropTypes.bool,
    name: PropTypes.string,
    projectResources: PropTypes.array,
    futureAbsences: PropTypes.array,
    resourceGroup: PropTypes.shape({ color: PropTypes.string }),
    crew: PropTypes.shape({ name: PropTypes.string, id: PropTypes.number }),
  }),
  setResource: PropTypes.func,
  projectEditParams: PropTypes.string,
  showArchived: PropTypes.bool,
}

function daysUntilStart(calendarInterval, project) {
  const calendarStartDate = calendarInterval.start
  const expectedStart = project.startDate || nextMonday(calendarStartDate)
  const daysUntilStart = DateTime.fromISO(expectedStart)
    .diff(calendarStartDate)
    .as("days")
  return adjustForDaylightSavingsTime(daysUntilStart)
}

function adjustForDaylightSavingsTime(days) {
  return Math.round(days)
}

function nextMonday(startDate) {
  return startDate.plus({ week: 1 }).startOf("week")
}

function groupNonEstimateBookingsByOffsetMargin(projects) {
  const bookingsByOffset = projects
    .map((project) => project.bookings)
    .flat()
    .reduce((result, booking) => {
      if (!booking.estimate) {
        result[booking.offsetMargin] ||= []
        result[booking.offsetMargin].push(booking)
      }
      return result
    }, [])
  return bookingsByOffset
}

function createAbsence(absenceFromDB, calendarInterval, dayWidth) {
  const startDate = DateTime.fromISO(absenceFromDB.startDate)
  const offsetInDays = Math.ceil(
    startDate.diff(calendarInterval.start).as("days")
  )
  const offsetMargin = offsetInDays * dayWidth
  return { ...absenceFromDB, offsetMargin, startDate, offsetInDays }
}

function createAbsences(absencesFromDB, calendarInterval, dayWidth) {
  let absenceGroupedByOffsetMargin = []
  let absenceWeeks = []

  absencesFromDB.forEach((absenceFromDB) => {
    const absence = createAbsence(absenceFromDB, calendarInterval, dayWidth)
    absenceGroupedByOffsetMargin[absence.offsetMargin] ||= []
    absenceGroupedByOffsetMargin[absence.offsetMargin] = absence

    const weekOffset = absence.startDate
      .startOf("week")
      .diff(calendarInterval.start)
      .as("days")
    const offsetMargin = weekOffset * dayWidth

    let index = absence.startDate.year + "" + absence.startDate.weekNumber

    absenceWeeks[index] ||= { offsetMargin, approved: absence.approved }
  })

  return { absenceGroupedByOffsetMargin, absenceWeeks }
}
