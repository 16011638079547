import React, { useState } from "react"
import { Roles, User } from "../Users"
import { useDeleteUser, useUpdateUser } from "../../hooks/useUsers"
import employee from "../../images/employee.svg"
import "./index.scss"

const UserUpdateForm = ({
  user,
  onSuccess,
}: {
  user: User
  onSuccess: () => void
}) => {
  const availableRoles: Roles[] = ["admin", "planner", "user"]

  const updateUser = useUpdateUser()
  const deleteUser = useDeleteUser()
  const [name, setName] = useState<User["name"]>(user.name || null)
  const [role, setRole] = useState<User["role"]>(user.role || "user")
  const [toggleConfirmDelete, setToggleConfirmDelete] = useState(false)

  const handleRoleClick = (role: Roles) => {
    setRole(role)
  }

  const roles = () => {
    return availableRoles.map((availablRole, index) => (
      <div
        key={index}
        onClick={() => handleRoleClick(availablRole)}
        className={`role border click-effect ${
          role === availablRole ? "border-success" : ""
        }`}
      >
        {availablRole}
      </div>
    ))
  }

  const handleSave = () => {
    if (user.id) {
      updateUser.mutate(
        { userId: user.id, name: name || "", role: role },
        {
          onSuccess: () => {
            onSuccess()
          },
        }
      )
    }
  }

  const handleDelete = () => {
    if (user.id) {
      deleteUser.mutate(user.id, {
        onSuccess: () => {
          onSuccess()
        },
      })
    }
  }

  return (
    <div className="p-5">
      {toggleConfirmDelete ? (
        <div className="d-flex flex-column align-items-center mt-5">
          <p>Er du sikker på du vil slette brugeren?</p>
          <button
            className="btn btn-danger rounded-0 w-100 mt-3"
            onClick={handleDelete}
          >
            Slet
          </button>
          <button
            className="btn btn-outline-primary rounded-0 w-100 mt-3"
            onClick={() => setToggleConfirmDelete(false)}
          >
            Fortryd
          </button>
        </div>
      ) : (
        <>
          <img className="user-card-image p-5" src={employee} />

          <h3>{user.email}</h3>
          <div className="form-group mt-3">
            <label htmlFor="name">Navn</label>
            <input
              className="form-control rounded-0"
              type="text"
              value={name || ""}
              onChange={(e) => setName(e.target.value)}
              id="navn"
            />
          </div>
          <div className="form-group">
            <label htmlFor="role">Rolle</label>
            <div className="roles">{roles()}</div>
          </div>
          <div className="dropdown-divider mt-3"></div>
          <button
            className="btn btn-primary rounded-0 w-100 mt-3"
            onClick={handleSave}
          >
            Gem
          </button>
          <button
            className="btn btn-danger rounded-0 w-100 mt-3"
            onClick={() => setToggleConfirmDelete(true)}
          >
            Slet
          </button>
        </>
      )}
    </div>
  )
}

export default UserUpdateForm
