import React from "react"
import employee from "../../images/employee.svg"
import { User } from "../Users"
import "./index.scss"

export default function UserCard({
  user,
  selected,
  onClick,
}: {
  user: User
  selected: boolean
  onClick: () => void
}) {
  return (
    <div
      className={`w-100 border p-3 user-card click-effect ${
        selected ? "border-success" : ""
      }`}
      onClick={onClick}
    >
      <div className="d-flex">
        <div className="user-card-container">
          <img className="user-card-image" src={employee} />
        </div>
        <div className="ml-3 mt-2">
          <p className="mb-0">{user.name || "John doe"}</p>
          <h5 className="">{user.email}</h5>
          <div className="user-card-roles">
            <div className="role border">{user.role}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
