import React, { useEffect, useState } from "react"
import "./index.scss"

const Sidebar = ({
  children,
  visible,
  onDismiss,
}: {
  children?: JSX.Element
  visible: boolean
  onDismiss: () => void
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  useEffect(() => {
    const handleEsc = (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        onDismiss()
      }
    }

    // Add event listener
    window.addEventListener("keydown", handleEsc)

    return () => {
      window.removeEventListener("keydown", handleEsc)
    }
  }, [])

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (!visible) {
      setIsSidebarOpen(false)
      timer = setTimeout(() => {
        setIsSidebarOpen(false)
      }, 100)

      return
    }

    timer = setTimeout(() => {
      setIsSidebarOpen(true)
    }, 100)

    return () => clearTimeout(timer)
  }, [visible])

  return (
    <>
      {visible && <div className="bg-shadow" onClick={onDismiss}></div>}
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>{children}</div>
    </>
  )
}

export default Sidebar
