import React from "react"
import PropTypes from "prop-types"
import "./index.scss"
import Select from "react-select"
function ProjectResourceAdder(props) {
  const { handleResourceSelect, idSuffix, resourceName, resources } = props

  function subContractor(resourceIndex) {
    const resource = resourcesSorted[resourceIndex]

    return resource.subContractor || resource.type == "SubContractor"
  }

  const resourcesSorted = [...resources]

  const internalResources = resourcesSorted
    .map((resource, index) => {
      return { label: resource.name, value: index }
    })
    .filter(
      (resource) =>
        !subContractor(resource.value) &&
        !resourcesSorted[resource.value].deletedAt
    )

  const externalResources = resourcesSorted
    .map((resource, index) => {
      return { label: resource.name, value: index }
    })
    .filter((resource) => subContractor(resource.value))
  const options = []

  options.push({
    label: "Interne",
    options: internalResources,
  })
  options.push({
    label: "Underentreprenører",
    options: externalResources,
  })

  return (
    <>
      <Select
        aria-label={`Tilføj ${resourceName}`}
        placeholder={`Tilføj ${resourceName}`}
        menuPlacement="top"
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 1030 }),
        }}
        id={`addProjectResource${idSuffix}`}
        onChange={(event) => handleResourceSelect(event.value)}
        options={options}
        controlShouldRenderValue={false}
      />
    </>
  )
}

ProjectResourceAdder.propTypes = {
  handleResourceSelect: PropTypes.func,
  idSuffix: PropTypes.any,
  resourceName: PropTypes.string,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ).isRequired,
}

export default ProjectResourceAdder
