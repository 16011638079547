import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import UserCard from "../UserCard"
import "./index.scss"
import Sidebar from "../Sidebar"
import UserUpdateForm from "../UserUpdateForm"
import UserCreateForm from "../UserCreateForm"

export type Roles = "admin" | "planner" | "user"

export interface User {
  id: number
  email: string
  updated_at: string
  name: string | null
  image_url: string | null
  role: Roles
}

export default function Users() {
  const [editUser, setEditUser] = useState<{
    user: User | null
    state: "open" | "closed"
  }>({ user: null, state: "closed" })

  const { data, isLoading, isError } = useQuery<User[]>("users", async () => {
    const response = await fetch("/api/v1/users")
    if (!response.ok) {
      throw new Error("Network response was not ok")
    }
    return response.json()
  })

  const [searchQuery, setSearchQuery] = useState("")
  const [searchResults, setSearchResults] = useState<
    User[] | null | undefined
  >()

  useEffect(() => {
    if (data) {
      setSearchResults(data)
    }
  }, [data])

  const handleSearch = (value: string) => {
    setSearchQuery(value)
    if (value === undefined) {
      handleReset()
    }
    if (value.trim() === "") {
      handleReset()
    } else {
      // Filter users based on the search query
      const filteredUsers = data?.filter(
        (user) =>
          user.name?.toLowerCase().includes(value.toLowerCase()) ||
          user.email.toLowerCase().includes(value.toLowerCase())
      )

      setSearchResults(filteredUsers || null)
    }
  }

  const handleReset = () => {
    setSearchQuery("")
    setSearchResults(data)
  }

  if (isLoading) {
    return (
      <div className="container">
        <div>Loading...</div>
      </div>
    )
  }

  if (isError) {
    return (
      <div className="container">
        <div>Error fetching data</div>
      </div>
    )
  }

  return (
    <>
      <div className="container mt-3">
        <div className="d-flex align-items-center">
          <div className="position-relative w-100">
            <input
              type="text"
              placeholder="Søg på navn eller email"
              className="border users-search px-2"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <div
              onClick={handleReset}
              className="close users-search-reset"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end mt-3">
          <button
            className="btn btn-success rounded-0"
            onClick={() => setEditUser({ user: null, state: "open" })}
          >
            Opret ny bruger
          </button>
        </div>

        <div className="users-cards mt-3">
          {searchResults?.map((user) => (
            <UserCard
              selected={editUser.user?.id === user.id}
              key={user.id}
              user={user}
              onClick={() => setEditUser({ user: user, state: "open" })}
            />
          ))}
        </div>
      </div>
      {}
      <Sidebar
        visible={editUser.state === "open"}
        onDismiss={() => setEditUser({ user: null, state: "closed" })}
      >
        {editUser.user ? (
          <UserUpdateForm
            user={editUser.user}
            onSuccess={() => setEditUser({ user: null, state: "closed" })}
          />
        ) : (
          <UserCreateForm
            onSuccess={() => setEditUser({ user: null, state: "closed" })}
          />
        )}
      </Sidebar>
    </>
  )
}
