import React from "react"
import PropTypes from "prop-types"
import "./index.scss"
import CalendarProjectResource from "../CalendarProjectResource"
import CalendarBooking from "../CalendarBooking"
import Warning from "../Warning"

function CalendarProjectResourceGroup(props) {
  const {
    dayWidth,
    showDays,
    offsetMargin,
    projectResourceGroup,
    doubleBookings,
    setProjectResourceGroup,
    calendarInterval,
  } = props
  const resourceGroup = projectResourceGroup.resourceGroup
  const bookings = projectResourceGroup.bookings
  const projectResources = projectResourceGroup.projectResources

  if (bookings.length == 0) {
    return null
  }
  const doubleBooked = checkCorrelation(projectResources, doubleBookings)
  const doubleBookedMsg = "Ressource med dobbelt bookings"

  return (
    <>
      <div className="col-2 border-right text-left resource--name p-2">
        <button
          className="w-100 text-right"
          onClick={() =>
            setProjectResourceGroup({
              ...projectResourceGroup,
              expanded: !projectResourceGroup.expanded,
            })
          }
        >
          {doubleBooked && (
            <Warning
              message={doubleBookedMsg}
              onClick={() =>
                setProjectResourceGroup({
                  ...projectResourceGroup,
                  expanded: !projectResourceGroup.expanded,
                })
              }
            />
          )}
          <span className="text-truncate font-weight-bold">
            {resourceGroup.name}{" "}
          </span>
          {projectResources.length > 0 && (
            <span className="font-weight-bold">
              {projectResourceGroup.expanded ? "-" : "+"}
            </span>
          )}
        </button>
      </div>
      <div className="col-10 p-0 project-group--booking-timeline">
        <div
          style={{ marginLeft: offsetMargin, position: "relative" }}
          className={`calendar-resource-group ${resourceGroup.name}`}
        >
          {bookings.map((booking) => (
            <CalendarBooking
              key={booking.id}
              offsetMargin={booking.offsetInDays * dayWidth}
              className="project-group--booking"
              dayWidth={dayWidth}
              showDays={showDays}
              color={resourceGroup.color}
            />
          ))}
        </div>
      </div>
      {projectResourceGroup.expanded &&
        projectResources.map((projectResource) => (
          <CalendarProjectResource
            color={resourceGroup.color}
            key={projectResource.id}
            projectResource={projectResource}
            dayWidth={dayWidth}
            calendarInterval={calendarInterval}
            showDays={showDays}
            offsetMargin={offsetMargin}
            doubleBookings={doubleBookings}
          />
        ))}
    </>
  )
}

CalendarProjectResourceGroup.propTypes = {
  setProjectResourceGroup: PropTypes.func,
  dayWidth: PropTypes.number.isRequired,
  showDays: PropTypes.bool,
  calendarInterval: PropTypes.object,
  doubleBookings: PropTypes.array,
  offsetMargin: PropTypes.number.isRequired,
  projectResourceGroup: PropTypes.shape({
    expanded: PropTypes.bool,
    bookings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        offsetInDays: PropTypes.number.isRequired,
      })
    ),
    resourceGroup: PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    projectResources: PropTypes.array,
  }).isRequired,
}

export default CalendarProjectResourceGroup

function checkCorrelation(projectResources, doubleBookings) {
  if (!doubleBookings) return false

  for (let obj1 of projectResources) {
    for (let obj2 of doubleBookings) {
      const idFromArray1 = obj1.resourceable.id
      const resourceableIdFromArray2 = obj2.resourceableId

      if (idFromArray1 === resourceableIdFromArray2) {
        return true
      }
    }
  }
  return false
}
