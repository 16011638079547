import React, { useEffect, useState } from "react"
import "./index.scss"
import timeline from "../../images/timeline.svg"
import team from "../../images/team.svg"
import logo from "../../images/byens-logo-dark.svg"
import users from "../../images/users.svg"
import menu from "../../images/menu.svg"
import employee from "../../images/employee.svg"
import signout from "../../images/sign-out.svg"

const Navbar = ({
  children,
  visible,
  canManageUsers,
  canManageCrews,
  crewsPath,
  usersPath,
  projectsPath,
  signOutPath,
  currentPath,
  csrfToken,
}: {
  children?: JSX.Element
  visible: boolean
  canManageUsers: boolean
  canManageCrews: boolean
  crewsPath: string
  usersPath: string
  projectsPath: string
  signOutPath: string
  currentPath: string
  csrfToken: string
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [isTextVisible, setIsTextVisible] = useState(false)

  useEffect(() => {
    const handleEsc = (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        // onDismiss()
      }
    }

    // Add event listener
    window.addEventListener("keydown", handleEsc)

    return () => {
      window.removeEventListener("keydown", handleEsc)
    }
  }, [])

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (!isSidebarOpen) {
      setIsTextVisible(false)
      document.getElementById("body")?.classList.remove("sidebar--open")

      return
    }

    document.getElementById("body")?.classList.add("sidebar--open")
    timer = setTimeout(() => {
      setIsTextVisible(true)
    }, 100)

    return () => clearTimeout(timer)
  }, [isSidebarOpen])

  console.log(currentPath)

  return (
    <>
      <div
        className={`side-nav border-right bg-white sticky-top ${
          isSidebarOpen ? "open" : ""
        }`}
      >
        <div
          className="side-nav-header height-top-bars border-bottom d-flex align-items-center justify-content-between"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <img
            src={logo}
            style={{ width: "60px" }}
            alt="double booked"
            className="ml-4"
          />
          {isTextVisible && (
            <img
              src={menu}
              style={{ width: "30px" }}
              alt="double booked"
              className="mr-4"
            />
          )}
        </div>

        <SiveNavItem
          text="Planlægning"
          logoSrc={timeline}
          isTextVisible={isTextVisible}
          path={projectsPath}
          active={
            projectsPath === currentPath ||
            currentPath.includes("resources") ||
            currentPath.includes("construction-managers")
          }
        />
        {canManageCrews && (
          <SiveNavItem
            text="Sjak"
            logoSrc={team}
            svgStyle={{ width: "50px", height: "50px" }}
            isTextVisible={isTextVisible}
            path={crewsPath}
            active={crewsPath === currentPath}
          />
        )}
        {canManageUsers && (
          <SiveNavItem
            text="Brugere"
            logoSrc={users}
            isTextVisible={isTextVisible}
            path={usersPath}
            active={usersPath === currentPath}
          />
        )}
        <div className="">
          <div className="dropdown-divider"></div>
          <SiveNavItemSignout
            text={"Log ud"}
            logoSrc={signout}
            csrfToken={csrfToken}
            isTextVisible={isTextVisible}
            active={false}
            path={signOutPath}
          />
        </div>
      </div>
    </>
  )
}

const SiveNavItem = ({
  text,
  logoSrc,
  path,
  isTextVisible,
  active = false,
  svgStyle = {
    width: "50px",
    height: "50px",
  },
}: {
  text: string
  logoSrc: any
  isTextVisible: boolean
  path?: string
  svgStyle?: { width: string; height: string }
  active: boolean
}) => {
  return (
    <div className={`side-nav-item mt-4 mb-4 `}>
      <a href={path} className=" d-flex flex-row align-items-center">
        <img
          className={`align-self-left ml-4 p-2 rounded ${
            active ? ` bg-dark ` : ""
          }`}
          src={logoSrc}
          style={svgStyle}
          alt={text + " button"}
        />
        {isTextVisible ? <p className="font-weight-bold">{text}</p> : <p></p>}
      </a>
    </div>
  )
}

const SiveNavItemSignout = ({
  text,
  logoSrc,
  path,
  isTextVisible,
  csrfToken,
  active = false,
  svgStyle = {
    width: "50px",
    height: "50px",
  },
}: {
  text: string
  logoSrc: any
  isTextVisible: boolean
  csrfToken: string
  path?: string
  svgStyle?: { width: string; height: string }
  active: boolean
}) => {
  return (
    <div className={`side-nav-item mt-4 `}>
      <form className="" action={path} method="post">
        <input type="hidden" value="delete" name="_method" />
        <input name="authenticity_token" type="hidden" value={csrfToken} />

        <button
          type="submit"
          className="p-0 m-0 border-0 bg-transparent  d-flex flex-row align-items-center"
        >
          <img
            className={`align-self-left ml-4 p-2 rounded ${
              active ? ` bg-dark ` : ""
            }`}
            src={logoSrc}
            style={svgStyle}
            alt={text + " button"}
          />
          {isTextVisible ? (
            <p className="font-weight-bold text-primary">{text}</p>
          ) : (
            <p></p>
          )}
        </button>
      </form>
    </div>
  )
}

export default Navbar
