import React, { useEffect, useState } from "react"
import { ConstructionManager, Project } from "../ConstructionManagersCalendar"
import { DateTime, Interval } from "luxon"
import CalendarBooking from "../CalendarBooking"
import "./index.scss"
import { createAbsences } from "../CalendarProjectResource"
import ConstructionManagerProject from "../ConstructionManagerProject"
import AbsenceTimeLine from "../AbsenceTimeLine"

const ConstructionManagerProjects = ({
  constructionManager,
  dayWidth,
  showDays,
  calendarInterval,
  setSelectedProject,
}: {
  constructionManager: ConstructionManager
  dayWidth: number
  showDays: boolean
  calendarInterval: Interval
  setSelectedProject: ({
    state,
    project,
  }: {
    state: "closed" | "open"
    project: Project | null
  }) => void
}) => {
  const [expanded, setExpanded] = useState(false)

  let filteredProjects = constructionManager.projects
    ?.filter(Boolean)
    .filter((project) => {
      if (!project.cachedEndDate) return false
      const now = DateTime.now()
      const cachedEndDate = DateTime.fromISO(project.cachedEndDate)
      const diffInDays = now.diff(cachedEndDate, "days").days
      return diffInDays < 30
    })

  const daysFromCalendarStart = (dateTime: DateTime | null) => {
    if (!calendarInterval.start) return 0
    if (dateTime === null) return 0

    return dateTime.diff(calendarInterval.start).as("days")
  }

  const nextMonday = (startDate: DateTime) => {
    return startDate.plus({ week: 1 }).startOf("week")
  }

  const combinedIntervals = filteredProjects?.map((project) => {
    const expectedStart = project.startDate
      ? DateTime.fromISO(project.startDate)
      : nextMonday(calendarInterval.start || DateTime.now())
    const endDate = expectedStart.plus({ days: project.durationInDays || 0 })

    return Interval.fromDateTimes(expectedStart, endDate)
  })

  useEffect(() => {
    console.log("eh?")
    setExpanded(constructionManager.expanded)
  }, [constructionManager.expanded])

  if (!constructionManager) return <></>
  if (!calendarInterval.start?.isValid) return <></>
  // if (filteredProjects.length === 0) return <></>

  return (
    <div className={"row border-bottom construction-manager-row"}>
      <div
        className="project--name col-2 border-right p-2 text-left"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="row">
          <div className="col">
            <div className="d-flex expand-resource-group align-items-center text-truncate justify-content-between">
              <span>
                <span className="font-weight-bold"></span>

                <span
                  className="font-weight-bold"
                  aria-label={"Expand " + constructionManager.name}
                >
                  {constructionManager.name}
                </span>
              </span>

              <span>
                <span>{expanded ? "-" : "+"}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-10 py-2 px-0 construction-manager-combined">
        {combinedIntervals?.map((combinedInterval) => (
          <>
            <div
              className={`timeline`}
              style={{
                width: combinedInterval.length("days") * dayWidth + "px",
                left:
                  daysFromCalendarStart(combinedInterval.start) * dayWidth +
                  "px",
                position: "absolute",
              }}
            />
          </>
        ))}
        <AbsenceTimeLine
          absences={constructionManager.futureAbsences}
          calendarInterval={calendarInterval}
          dayWidth={dayWidth}
          showDays={showDays}
        />
      </div>
      {expanded &&
        filteredProjects?.map((project, index) => (
          <ConstructionManagerProject
            key={index}
            project={project}
            constructionManager={constructionManager}
            showDays={showDays}
            dayWidth={dayWidth}
            setSelectedProject={setSelectedProject}
            calendarInterval={calendarInterval}
          />
        ))}
      {expanded && (
        <>
          <div className="col-2 border-right text-left project--actions p-2"></div>
          <div className="col-10"></div>
        </>
      )}
    </div>
  )
}

export default ConstructionManagerProjects
