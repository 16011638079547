function camelize(text) {
  text = text.replace(/[-_\s.]+(.)?/g, (_, c) => {
    return c ? c.toUpperCase() : ""
  })
  return text.substr(0, 1).toLowerCase() + text.substr(1)
}

export default function camelizeProps(props) {
  if (Array.isArray(props)) {
    return props.map((item) => camelizeProps(item))
  } else if (props && typeof props == "object") {
    let camelizedObject = {}
    Object.entries(props).forEach(
      ([key, value]) => (camelizedObject[camelize(key)] = camelizeProps(value))
    )
    return camelizedObject
  }
  return props
}
