import React, { useEffect, useState } from "react"
import "./index.scss"
import { DateTime } from "luxon"
import { Project } from "../ConstructionManagersCalendar"

function ProjectDetails({
  project,
  canEditProjects,
  editParams,
  calendarType,
}: {
  project: Project
  editParams: string
  calendarType: "resources" | "projects" | "construction_managers"
  canEditProjects: boolean
}) {
  console.log(project)
  const [status, setStatus] = useState({ status: "planning", text: "Planlagt" })
  const formattedEditParams = editParams ? `&${editParams}` : ""
  console.log(editParams)
  useEffect(() => {
    const nowMillis = DateTime.now().toMillis()
    const startOfDayMillis = DateTime.now().startOf("day").toMillis()
    const startDateMillis = project.startDate
      ? DateTime.fromISO(project.startDate).toMillis()
      : null
    const cachedEndDateMillis = project.cachedEndDate
      ? DateTime.fromISO(project.cachedEndDate).toMillis()
      : null

    if (startDateMillis && startDateMillis > startOfDayMillis) {
      const daysUntilStart = DateTime.now()
        .startOf("day")
        .until(DateTime.fromMillis(startDateMillis))
        .length("days")
      setStatus({
        status: "starting",
        text: `Starter om ${daysUntilStart} dage`,
      })
    } else if (cachedEndDateMillis && cachedEndDateMillis < nowMillis) {
      setStatus({ status: "completed", text: "Afsluttet" })
    } else if (
      startDateMillis &&
      cachedEndDateMillis &&
      nowMillis >= startDateMillis
    ) {
      setStatus({ status: "started", text: "Igang" })
    }
  }, [project.startDate, project.cachedEndDate])

  return (
    <div className="p-5">
      <div className="d-flex align-items-center gap">
        <span className={`project--status ${status.status}`}></span>
        <h5 className="mb-0">
          {project.id} {project.name}
        </h5>
      </div>
      <div className="project-details mt-5">
        <h6>Byggeleder:</h6>
        {project.constructionManager?.name ? (
          <p>
            <a
              href={`/projects?q[g][1][project_manager_or_name_or_construction_manager_initials_or_construction_manager_name_or_crew_name_or_start]=${project.constructionManager.initials}&q[g][1][m]=or&q[g][1][id_eq]=${project.constructionManager.initials}`}
            >
              {project.constructionManager?.name}
            </a>
          </p>
        ) : (
          <p>{"---"}</p>
        )}
        <h6>Produktionsleder:</h6>
        <p>{project.projectManager || "---"}</p>
        <h6>Status:</h6>
        <p>{status.text}</p>
        <h6>Start dato: </h6>
        <p>{project.startDate || "---"}</p>
        <h6>Slut dato: </h6>
        <p>{project.cachedEndDate || "---"}</p>
        <h6>Projektets længde: </h6>
        <p>{project.durationInDays || 0} dage</p>

        <h6>Note:</h6>
        <p>{project.note || "---"}</p>
      </div>
      {project.projectLinks.map((projectLink) => (
        <div className="mt-4">
          <a href={projectLink.url} className="text-success" target="_blank">
            <u>{projectLink.title}</u>
          </a>
        </div>
      ))}
      <div className="form-group mt-5">
        <div className="actions">
          {canEditProjects && (
            <a
              href={`/projects/${project.id}/edit?calendar_type=${calendarType}${formattedEditParams}`}
              className={`border click-effect py-1 px-2`}
            >
              Rediger projekt
            </a>
          )}
          <a
            href={`/projects?q[g][1][project_manager_or_name_or_construction_manager_initials_or_construction_manager_name_or_crew_name_or_start]=${project.name}&q[g][1][m]=or&q[g][1][id_eq]=${project.name}`}
            className={`border click-effect py-1 px-2`}
          >
            Se projekt
          </a>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetails
