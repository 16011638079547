import React, { useContext, useRef } from "react"
import PropTypes from "prop-types"
import shadeColor from "../../utils/shadeColor"
import { Overlay, Tooltip } from "react-bootstrap"
import { ProjectBookerContext } from "../../contexts/ProjectBookerContext"
import "./index.scss"

function ProjectBooking(props) {
  const {
    startDate,
    durationInDays,
    firstOffsetShown,
    lastOffsetShown,
    dayWidth,
  } = useContext(ProjectBookerContext)

  const {
    color,
    booking,
    pending,
    selected,
    tooltipText,
    parentInputFieldPrefix,
    onClick,
    onMouseOver,
  } = props

  const offsetInDays = booking.offsetInDays
  const hideAndDestroy = offsetInDays + 1 > durationInDays
  const hide =
    hideAndDestroy ||
    offsetInDays < firstOffsetShown ||
    offsetInDays > lastOffsetShown
  const booked = !(hideAndDestroy || booking.destroy)

  const _color = color || "#cfe2ff"
  let computedColor = null
  if (booked) {
    computedColor = _color
  }
  if (pending) {
    computedColor = shadeColor(_color, 10)
  }
  if (selected) {
    computedColor = shadeColor(_color, -10)
  }

  function handleClick() {
    onClick(booking)
  }

  function handleMouseOver() {
    onMouseOver(booking)
  }

  const ref = useRef()

  return (
    <div
      className={"btn-group-toggle pt-1"}
      data={{ toggle: "tooltip", placement: "top", title: tooltipText }}
      style={{ display: hide ? "none" : "inline-block" }}
      ref={ref}
      onMouseOver={handleMouseOver}
    >
      <Overlay target={ref} show={!!tooltipText} transition={false}>
        {(props) => <Tooltip {...props}>{tooltipText}</Tooltip>}
      </Overlay>
      <input
        type="hidden"
        name={`${parentInputFieldPrefix}[bookings_attributes][${offsetInDays}][id]`}
        value={booking.id}
      />
      <input
        type="hidden"
        name={`${parentInputFieldPrefix}[bookings_attributes][${offsetInDays}][offset_in_days]`}
        value={offsetInDays}
      />
      <label
        className={`btn p-0 booking ${booked ? "active" : "inactive"}`}
        style={{ backgroundColor: computedColor, width: dayWidth }}
        title={startDate?.plus({ days: offsetInDays }).toISODate()}
        aria-label={`Book dag ${offsetInDays + 1}`}
      >
        <input
          checked={!booked}
          name={`${parentInputFieldPrefix}[bookings_attributes][${offsetInDays}][_destroy]`}
          onChange={handleClick}
          type="checkbox"
          value="1"
        />
      </label>
    </div>
  )
}

ProjectBooking.propTypes = {
  booking: PropTypes.shape({
    id: PropTypes.number,
    offsetInDays: PropTypes.number,
    destroy: PropTypes.bool,
  }).isRequired,
  color: PropTypes.string,
  parentInputFieldPrefix: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func,
  hideAndDestroy: PropTypes.bool,
  pending: PropTypes.bool,
  selected: PropTypes.bool,
  tooltipText: PropTypes.string,
}

export default ProjectBooking
