import React, { useState } from "react"
import ProjectEndDate from "../ProjectEndDate"
import ProjectStartDate from "../ProjectStartDate"
import PropTypes from "prop-types"

import "./index.scss"

function ProjectDates(props) {
  const { startDateFromDb } = props
  const [startDate, setStartDate] = useState(startDateFromDb)

  return (
    <>
      <div className="row">
        <div className="col-6">
          <ProjectStartDate startDate={startDate} setStartDate={setStartDate} />
        </div>
        <div className="col-6">
          <ProjectEndDate
            startDate={startDate}
            durationInputSelector="#project_duration_in_days"
          />
        </div>
      </div>
    </>
  )
}

ProjectDates.propTypes = {
  startDateFromDb: PropTypes.string,
}

export default ProjectDates
