import React, { useContext } from "react"
import PropTypes from "prop-types"
import ProjectBooking from "../ProjectBooking"
import ProjectWeekBooking from "../ProjectWeekBooking"
import { ProjectBookerContext } from "../../contexts/ProjectBookerContext"
import useBookingSelector from "../../hooks/useBookingSelector"
import intervalFromOffsets from "../../utils/intervalFromOffsets"

export default function ProjectBookings(props) {
  const { bookings, setBookings, color, inputFieldPrefix } = props

  const { firstOffsetShown, lastOffsetShown, showDays } =
    useContext(ProjectBookerContext)

  const {
    bookingPending,
    bookingSelected,
    bookingTooltipText,
    handleBookingClick,
    handleWeekClick,
    handleBookingMouseOver,
    handleWeekMouseOver,
  } = useBookingSelector(bookings, setBookings)

  return (
    <>
      {!showDays &&
        createWeeks(
          firstOffsetShown,
          lastOffsetShown,
          handleWeekClick,
          handleWeekMouseOver
        )}
      {bookings.map((booking) => (
        <ProjectBooking
          booking={booking}
          key={booking.offsetInDays}
          color={color}
          parentInputFieldPrefix={inputFieldPrefix}
          pending={bookingPending(booking)}
          selected={bookingSelected(booking)}
          tooltipText={bookingTooltipText(booking)}
          onClick={handleBookingClick}
          onMouseOver={handleBookingMouseOver}
        />
      ))}
    </>
  )
}

function createWeeks(
  firstOffsetShown,
  lastOffsetShown,
  handleClick,
  handleMouseOver
) {
  if (lastOffsetShown == -1) {
    return
  }

  const weeks = intervalFromOffsets(firstOffsetShown, lastOffsetShown).weeks()

  return (
    <div className="pt-1" style={{ position: "absolute" }}>
      {weeks.map((week, index) => (
        <ProjectWeekBooking
          key={index}
          week={week}
          onClick={handleClick}
          onMouseOver={handleMouseOver}
        />
      ))}
    </div>
  )
}

ProjectBookings.propTypes = {
  bookings: PropTypes.array,
  setBookings: PropTypes.func,
  color: PropTypes.string,
  inputFieldPrefix: PropTypes.string,
}
