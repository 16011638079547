import React, { useState } from "react"
import { Next } from "react-bootstrap/esm/PageItem"
import Select, { MultiValue } from "react-select"

export type Employee = {
  id: number
  name: string
  color: string
  resource_group_id: number
}

export type ResourceGroup = {
  id: number
  name: string
}

export type PropType = {
  employees: Employee[]
  resourceGroups: ResourceGroup[]
  assignedEmployees?: Employee[]
}

type Option = {
  id: number
  label: string
  options: Employee[]
}

export default function CrewEmployeeSelect(props: PropType) {
  const {
    employees,
    resourceGroups,
    assignedEmployees: existingEmployees,
  } = props
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>(
    existingEmployees ?? []
  )

  const options: Option[] = []
  resourceGroups.forEach((resourceGroup) => {
    options.push({
      id: resourceGroup.id,
      label: resourceGroup.name,
      options: [],
    })
  })

  employees.forEach((employee) => {
    const index = options.findIndex((o) => o.id == employee.resource_group_id)

    if (index === -1) {
      return
    }

    options[index].options.push(employee)
  })

  function handleEmployeeSelect(event: MultiValue<Employee>) {
    setSelectedEmployees(event.map((employee) => employee))
  }

  return (
    <>
      <Select
        name="SelectEmployee"
        inputId="SelectEmployee"
        isMulti={true}
        options={options}
        classNamePrefix="employee-select"
        getOptionValue={(option: Employee) => String(option.id)}
        getOptionLabel={(option: Employee) => option.name}
        onChange={(event) => handleEmployeeSelect(event)}
        defaultValue={existingEmployees}
      />
      {selectedEmployees.map((selectedEmployee, index) => (
        <input
          key={index}
          type="hidden"
          name="crew[employee_ids][]"
          value={selectedEmployee.id}
        />
      ))}
    </>
  )
}
