import PropTypes from "prop-types"
import React from "react"

export default function ResourceCalendarGroupFilter(props) {
  const { groups, className, formRef } = props

  const urlParams = new URLSearchParams(window.location.search)
  const query = urlParams.get("q[g][0][resource_group_name_eq]") || ""

  function handleOnChange() {
    formRef.current.submit()
  }

  return (
    <select
      name="q[g][0][resource_group_name_eq]"
      onChange={handleOnChange}
      className={`${className}  custom-select font-weight-bold bg-transparent`}
      aria-label="Filtrér på faggruppe"
      value={query}
    >
      <option value="">Filtre</option>
      {groups.map((group, index) => (
        <option key={index} value={group.name}>
          {group.name}
        </option>
      ))}
    </select>
  )
}

ResourceCalendarGroupFilter.propTypes = {
  className: PropTypes.string,
  groups: PropTypes.array,
  formRef: PropTypes.shape({
    current: PropTypes.shape({
      submit: PropTypes.func,
    }),
  }),
}
